<template>
  <div class="general-wrapp" v-if="caseData != null">
    <CaseNavigation
      :activeTab="2"
      :caseData="caseData"
    ></CaseNavigation>

    <div class="container mx-auto">
      <section class="flex items-center justify-items-stretch">
        <input
          v-model="searchTerm"
          @input="handleSearch"
          class="w-1/3 p-4 text-sm font-medium text-gray-300 placeholder-gray-400 bg-transparent outline-none border-2 border-gray-500"
          id="pageHeaderInput5-1"
          type="search"
          placeholder="Pretraži dokumente..."
        />

        <div class="flex flex-justify ml-4 items-center flex-1">
          <div class="">
            <span
              class="inline-block py-2 px-4 mb-3 mr-3 text-gray-300 leading-6 bg-custom-container rounded-full"
              >Svi dokumenti</span
            >
          </div>
          <div @click="mergeFiles()">
            <span class="inline-block flex justify-center">
              <div class="inline-block flex justify-center py-2 px-4 mb-3 mr-3 text-gray-200 leading-6 border border-custom-container rounded-full hover:bg-custom-container hover:text-white cursor-pointer" v-if="files.length" >
                <svg
                  class="w-6 h-5 mr-2"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  v-if="!is_downloading"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M12 1.25C11.5858 1.25 11.25 1.58579 11.25 2V12.9726L9.56944 11.0119C9.29988 10.6974 8.8264 10.661 8.51191 10.9306C8.19741 11.2001 8.16099 11.6736 8.43056 11.9881L11.4306 15.4881C11.573 15.6543 11.7811 15.75 12 15.75C12.2189 15.75 12.427 15.6543 12.5694 15.4881L15.5694 11.9881C15.839 11.6736 15.8026 11.2001 15.4881 10.9306C15.1736 10.661 14.7001 10.6974 14.4306 11.0119L12.75 12.9726L12.75 2C12.75 1.58579 12.4142 1.25 12 1.25Z"
                      fill="#e5e7eb"
                    ></path>
                    <path
                      d="M14.25 9V9.37828C14.9836 9.11973 15.8312 9.2491 16.4642 9.79167C17.4077 10.6004 17.517 12.0208 16.7083 12.9643L13.7083 16.4643C13.2808 16.963 12.6568 17.25 12 17.25C11.3431 17.25 10.7191 16.963 10.2916 16.4643L7.29163 12.9643C6.48293 12.0208 6.5922 10.6004 7.53568 9.79167C8.16868 9.2491 9.01637 9.11973 9.74996 9.37828V9H8C5.17157 9 3.75736 9 2.87868 9.87868C2 10.7574 2 12.1716 2 15V16C2 18.8284 2 20.2426 2.87868 21.1213C3.75736 22 5.17157 22 7.99999 22H16C18.8284 22 20.2426 22 21.1213 21.1213C22 20.2426 22 18.8284 22 16V15C22 12.1716 22 10.7574 21.1213 9.87868C20.2426 9 18.8284 9 16 9H14.25Z"
                      fill="#e5e7eb"
                    ></path>
                  </g>
                </svg>
                <span v-if="!is_downloading">Preuzmi sve</span>
                <svg
                v-if="is_downloading"
                aria-hidden="true"
                class="inline w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              </div>

             
            </span>
          </div>
        </div>
        <div class="">
          <div class="w-auto px-3 mb-4">
            <div class="relative inline-block rounded-xl">
              <select
                v-model="selectedOrder"
                @change="handleOrderChange"
                class="relative py-2 pl-6 pr-8 cursor-pointer text-gray-300 font-semibold appearance-none outline-none rounded-full bg-transparent border-2 border-gray-500"
                style="z-index: 1"
              >
                <option value="newToOld">Noviji - Stariji</option>
                <option value="oldToNew">Stariji - Noviji</option></select
              ><span
                class="absolute top-1/2 right-0 mr-2 transform -translate-y-1/2"
                style="z-index: 0"
              >
                <svg
                  width="10"
                  height="7"
                  viewbox="0 0 10 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 1.5L5 5.5L9 1.5"
                    stroke="#3D485B"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path></svg></span>
            </div>
          </div>
        </div>
      </section>
    </div>

    <section class="py-3">
      <div>
        <section class="py-8">
          <div class="container mx-auto">
            <div class="flex flex-wrap -mx-5 -mb-6">
              <SingleFile
                v-if="files.length > 0"
                v-for="(file, key) in files"
                :key="key"
                :file="file"
                :canShowCase="true"
                :showDetails="true"
              ></SingleFile>
              <div class="mx-5 -mb-6 text-white" v-else>
                Nema dijeljenih dokumenta
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>

    <!-- <section class="py-3"><div class="container px-4 mx-auto">
                  <div class="px-5 py-6 bg-custom-container rounded-xl">
                    <div class="flex h-8 mx-auto w-min">
                      <a class="inline-flex items-center justify-center w-8 bg-gray-600 border-r border-gray-500 text-gray-400 hover:text-gray-300 rounded-l-lg" href="#">
                        <svg width="4" height="6" viewbox="0 0 4 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.6454 2.99997L3.4154 1.22997C3.50852 1.13629 3.5608 1.00957 3.5608 0.877474C3.5608 0.745381 3.50852 0.618656 3.4154 0.524974C3.36892 0.47811 3.31362 0.440913 3.25269 0.415529C3.19176 0.390144 3.12641 0.377075 3.0604 0.377075C2.99439 0.377075 2.92904 0.390144 2.86811 0.415529C2.80718 0.440913 2.75188 0.47811 2.7054 0.524974L0.585399 2.64497C0.538535 2.69146 0.501338 2.74676 0.475954 2.80769C0.450569 2.86862 0.4375 2.93397 0.4375 2.99997C0.4375 3.06598 0.450569 3.13133 0.475954 3.19226C0.501338 3.25319 0.538535 3.30849 0.585399 3.35497L2.7054 5.49997C2.75212 5.54631 2.80753 5.58298 2.86845 5.60786C2.92936 5.63274 2.9946 5.64536 3.0604 5.64497C3.1262 5.64536 3.19143 5.63274 3.25235 5.60786C3.31327 5.58298 3.36868 5.54631 3.4154 5.49997C3.50852 5.40629 3.5608 5.27957 3.5608 5.14747C3.5608 5.01538 3.50852 4.88866 3.4154 4.79497L1.6454 2.99997Z" fill="currentColor"></path></svg></a>
                      <a class="inline-flex items-center justify-center w-8 bg-gray-600 border-r border-gray-500 text-sm text-gray-100 font-semibold" href="#">1</a><a class="inline-flex items-center justify-center w-8 bg-gray-600 border-r border-gray-500 text-sm text-gray-400 hover:text-gray-300 font-semibold" href="#">2</a>
                      <a class="inline-flex items-center justify-center w-8 bg-gray-600 text-gray-400 hover:text-gray-300 rounded-r-lg" href="#">
                        <svg width="4" height="6" viewbox="0 0 4 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.3546 3.00003L0.584601 4.77003C0.491475 4.86371 0.439205 4.99043 0.439205 5.12253C0.439205 5.25462 0.491475 5.38134 0.584601 5.47503C0.631083 5.52189 0.686383 5.55909 0.747313 5.58447C0.808242 5.60986 0.873595 5.62293 0.939601 5.62293C1.00561 5.62293 1.07096 5.60986 1.13189 5.58447C1.19282 5.55909 1.24812 5.52189 1.2946 5.47503L3.4146 3.35503C3.46146 3.30854 3.49866 3.25324 3.52405 3.19231C3.54943 3.13138 3.5625 3.06603 3.5625 3.00003C3.5625 2.93402 3.54943 2.86867 3.52405 2.80774C3.49866 2.74681 3.46146 2.69151 3.4146 2.64503L1.2946 0.500026C1.24788 0.453685 1.19247 0.417023 1.13155 0.392141C1.07063 0.367258 1.0054 0.354645 0.9396 0.355026C0.873797 0.354645 0.808566 0.367258 0.747647 0.392141C0.686728 0.417023 0.631321 0.453686 0.5846 0.500026C0.491475 0.593707 0.439204 0.720433 0.439204 0.852526C0.439204 0.984619 0.491475 1.11134 0.584601 1.20503L2.3546 3.00003Z" fill="currentColor"></path></svg></a>
                    </div>
                  </div>
                  </div>
                </section> -->
  </div>
</template>
<script>
import { ref, onMounted } from "vue";
import gsap from "gsap";
import router from "@/router";
import store from "@/store";
import moment from "moment";
import CaseNavigation from "@/components/Navigation/CaseNavigation.vue";
import { getSingleCaseOverview } from "@/services/case.service";
import SingleFile from "../../components/SingleFile.vue";
import { mergeCaseFiles } from "@/services/case.service";

export default {
  name: "CaseFiles",
  components: {
    SingleFile,
  },
  setup() {
    const caseData = ref(null);
    const files = ref([]);
    const single_case_uid = router.currentRoute.value.params.uid;
    const searchTerm = ref("");
    const selectedOrder = ref("newToOld"); // Default order
    const is_downloading = ref(false);

    const handleOrderChange = () => {
      // You can perform the sorting here based on the selected order
      // For example, using JavaScript's sort method
      if (selectedOrder.value === "newToOld") {
        files.value.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );
      } else if (selectedOrder.value === "oldToNew") {
        files.value.sort(
          (a, b) => new Date(a.created_at) - new Date(b.created_at)
        );
      }
    };

    onMounted(async () => {
      await getSingleCaseOverview(single_case_uid).then((resp) => {
        caseData.value = resp.data;
        files.value = resp.data.shared_files;

        gsap.from(".general-wrapp", {
          duration: 1,
          x: -100,
          opacity: 0,
          ease: "power1.out",
          force3D: true,
        });

        console.log(caseData.value);
      });
    });
    const returnTimestamp = (dt) => {
      moment().locale("hr");
      return moment(dt).format("DD.MM.YYYY.");
    };
    const isExpired = (dt) => {
      const currentTimestamp = Date.now();
      return currentTimestamp > new Date(dt).getTime();
    };

    const returnFileType = (file_name) => {
      var idx_ext = file_name.split(".").length - 1;
      var extension = file_name.split(".")[idx_ext].toLowerCase();

      var return_val = require("@/assets/img/doc_icon.svg");
      switch (extension) {
        case "docx":
          return_val = require("@/assets/img/doc_icon.svg");
          break;
        case "dotx":
          return_val = require("@/assets/img/doc_icon.svg");
          break;
        case "dotm":
          return_val = require("@/assets/img/doc_icon.svg");
          break;
        case "pptx":
          return_val = require("@/assets/img/ppt-icon.png");
          break;
        case "ppt":
          return_val = require("@/assets/img/ppt-icon.png");
          break;
        case "doc":
          return_val = require("@/assets/img/doc_icon.svg");
          break;
        case "pdf":
          return_val = require("@/assets/img/pdf_icon.svg");
          break;
        case "csv":
          return_val = require("@/assets/img/xls_icon.svg");
          break;
        case "xlsx":
          return_val = require("@/assets/img/xls_icon.svg");
          break;
        case "jpg":
          return_val = require("@/assets/img/jpg_icon.svg");
          break;
        case "jpeg":
          return_val = require("@/assets/img/jpg_icon.svg");
          break;
        case "png":
          return_val = require("@/assets/img/png_icon.svg");
          break;
        case "folder":
          return_val = require("@/assets/img/folder_yellow.svg");
          break;
        default:
          return_val = require("@/assets/img/dat_icon.svg");
          break;
      }
      return return_val;
    };

    // ... existing setup code ...

    const handleSearch = () => {
      // You can perform the filtering here based on the searchTerm
      // For example, using JavaScript's filter method
      files.value = caseData.value.shared_files.filter((file) =>
        file.file_name.toLowerCase().includes(searchTerm.value.toLowerCase())
      );
    };

    const clearSearch = () => {
      searchTerm.value = "";
      // Reset the files to the original list when search is cleared
      files.value = caseData.value.shared_files;
    };
    const mergeFiles = async () => {
      if (!is_downloading.value) {
        is_downloading.value = true;
        try {
          await mergeCaseFiles(caseData.value.case_uri).then((resp) => {
            var newBlob = new Blob([resp.data], {
              type: "application/pdf",
            });
            var data = window.URL.createObjectURL(newBlob);

            var link = document.createElement("a");
            link.href = data;
            link.download = caseData.value.case_name+".pdf";
            link.click();
            setTimeout(function () {
              // For Firefox it is necessary to delay revoking the ObjectURL
              window.URL.revokeObjectURL(data);
            }, 100);
          });
        } catch (e) {
          alert(
            "Trenutno nije moguće preuzeti dokumente, molimo pokušajte kasnije ili kontaktirajte zadužene članove tima."
          );
          
        }
        is_downloading.value = false;
      }
    };
    return {
      files,
      caseData,
      searchTerm,
      selectedOrder,
      is_downloading,
      handleOrderChange,
      returnFileType,
      returnTimestamp,
      isExpired,
      handleSearch,
      clearSearch,
      mergeFiles,
    };
  },
  components: { CaseNavigation, SingleFile },
};
</script>
