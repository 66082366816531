<template>
  <section>
    <div class="py-8 container mx-auto">
      <div class="flex flex-wrap items-center -mx-2">
        <div class="w-full md:w-auto px-2 mb-8 md:mb-0" id="ob-case-1">
          <h4
            class="text-2xl font-bold text-white leading-5"
            contenteditable="false"
          >
            {{ case_data.case_name }}
          </h4>
        </div>

        <div class="w-full md:w-auto px-2">
          <div class="flex flex-wrap items-center -m-2">
            <span
              v-if="deadline"
              class="px-2.5 py-1 text-sm font-medium text-yellow-500 bg-blue-500 bg-opacity-10 rounded-full hover:bg-none hover:text-blue-300 cursor-pointer"
            >
              <div>{{ fromNow(deadline.expiry_date) }}</div>
            </span>
            <div class="w-auto p-2 mr-8">
              <!-- <a class="inline-flex h-12 py-1 px-5 items-center font-semibold text-blue-50 bg-blue-500 hover:bg-blue-600 transition duration-200 rounded-lg" href="#">
                              <svg width="15" height="14" viewbox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.3333 6.16668H8.33334V1.16668C8.33334 0.945663 8.24555 0.733702 8.08927 0.577421C7.93299 0.421141 7.72102 0.333344 7.50001 0.333344C7.279 0.333344 7.06703 0.421141 6.91075 0.577421C6.75447 0.733702 6.66668 0.945663 6.66668 1.16668V6.16668H1.66668C1.44566 6.16668 1.2337 6.25447 1.07742 6.41075C0.921141 6.56703 0.833344 6.779 0.833344 7.00001C0.833344 7.22102 0.921141 7.43299 1.07742 7.58927C1.2337 7.74555 1.44566 7.83334 1.66668 7.83334H6.66668V12.8333C6.66668 13.0544 6.75447 13.2663 6.91075 13.4226C7.06703 13.5789 7.279 13.6667 7.50001 13.6667C7.72102 13.6667 7.93299 13.5789 8.08927 13.4226C8.24555 13.2663 8.33334 13.0544 8.33334 12.8333V7.83334H13.3333C13.5544 7.83334 13.7663 7.74555 13.9226 7.58927C14.0789 7.43299 14.1667 7.22102 14.1667 7.00001C14.1667 6.779 14.0789 6.56703 13.9226 6.41075C13.7663 6.25447 13.5544 6.16668 13.3333 6.16668Z" fill="#5E81FC"></path></svg><span class="ml-2" contenteditable="false">Unesi upit</span>
                            </a> -->
              <CaseComunication :case_name="case_data.case_name"  @message-sent="caseEmits"/>
            </div>
          </div>
        </div>

        <div class="w-full px-2 mt-8">
          <ul class="flex flex-wrap -mb-4 py-4">
            <li class="mb-4 mr-8" id="ob-case-2">
              <a
                :class="[
                  'inline-block',
                  'pb-4',
                  'font-semibold',
                  'border-b',
                  'transition duration-200',
                  activeTab == 1
                    ? 'text-blue-500 border-blue-500'
                    : 'text-gray-400 border-transparent',
                ]"
                href="#"
                @click="changeTab('/')"
                >Općenito</a
              >
            </li>
            <li class="mb-4 mr-8">
              <a
                :class="[
                  'inline-block',
                  'pb-4',
                  'font-semibold',
                  'border-b',
                  'transition duration-200',
                  activeTab == 2
                    ? 'text-blue-500 border-blue-500'
                    : 'text-gray-400 border-transparent',
                ]"
                href="#"
                @click="changeTab('/file')"
                >Dijeljeni dokumenti</a
              >
            </li>
            <!-- <li class="mb-4 mr-8"><a :class="['inline-block', 'pb-4','font-semibold', 'border-b', 'transition duration-200', activeTab == 3 ? 'text-blue-500 border-blue-500':'text-gray-400 border-transparent']"  href="#" @click="changeTab('/rocista')">Ročišnik</a></li> -->
            <li class="mb-4 mr-8">
              <a
                :class="[
                  'inline-block',
                  'pb-4',
                  'font-semibold',
                  'border-b',
                  'transition duration-200',
                  activeTab == 4
                    ? 'text-blue-500 border-blue-500'
                    : 'text-gray-400 border-transparent',
                ]"
                href="#"
                @click="changeTab('/comunication')"
                >Upiti</a
              >
            </li>

            <!-- <li class="mb-4"><a :class="['inline-block', 'pb-4','font-semibold', 'border-b', 'transition duration-200', activeTab == 4 ? 'text-blue-500 border-blue-500':'text-gray-400 border-transparent']"  href="#" @click="changeTab('/financial')">Financije</a></li> -->
          </ul>
          <div class="px-2 mt-2 text-left " v-if="deadline">
            <span class="text-gray-100 flex  items-center"><span class="text-3xl font-bold  w-4 h-6">*</span> Uskoro nećete imati pristup predmetu. Molimo vas da svu željenu dokumentaciju pohranite na lokalnom računalu. </span>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import router from "@/router";
import CaseComunication from "@/components/Modals/CaseComunication.vue";
import moment from "moment";

export default {
  components: {
    CaseComunication,
  },
  props: {
    activeTab: {
      type: Number,
      required: true,
    },
    caseData: {
      type: Object,
      required: true,
    },

  },
  emits: ["message-sent"],
  setup(props, context) {
    const case_id = router.currentRoute.value.params.uid;

    console.log(case_id);

    const changeTab = (tab) => {
      router.push("/case/" + case_id + tab);
    };
    const caseEmits = (dataEmit)=>{
      console.log("Data emit", dataEmit)
      context.emit("message-sent", dataEmit);
    }
    const fromNow = (date) => {
      console.log("Date", date);
      return "Ističe " + moment(date).locale("hr").format("LL");
    };
    return { changeTab,fromNow, case_data : props.caseData, deadline : props.caseData.deadline, caseEmits };
  },
};
</script>

<style>
/* Add your styles here */
</style>
