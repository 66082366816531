<template>
  <OnboardingCustom
    v-if="is_onboarding_visible"
    :steps="steps"
  ></OnboardingCustom>

  <div class="mx-auto" v-if="dashboardData != null">
    <section class="container px-4 mx-auto">
      <div class="pt-8 pb-2">
        <div class="pb-6 border-b-2 border-gray-500">
          <h4
            class="text-2xl font-bold text-white tracking-wide leading-6 mb-1"
          >
            Popis predmeta
          </h4>

          <p
            class="text-xs leading-5 text-gray-300"
            contenteditable="false"
          ></p>
        </div>
      </div>
    </section>
    <section class="py-4 overflow-hidden" >
      <div class="container px-4 mx-auto">
        <div
          class="pt-5 pb-7 bg-neutral-50 border border-neutral-100 rounded-xl" id="table-wrapper"
        >
          <div class="px-6">
            <div class="mb-12 w-full overflow-x-auto">
              <table class="w-full min-w-max " >
                <thead class="text-lg">
                  <tr class="text-left">
                    <th class="p-0">
                      <div class="pb-3.5">
                        <a class="inline-flex items-center" href="#">
                          <span class="mr-2.5 text-gray-400 font-bold"
                            >Broj predmeta</span
                          >
                        </a>
                      </div>
                    </th>
                    <th class="p-0">
                      <div class="pb-3.5">
                        <a class="inline-flex items-center" href="#">
                          <span class="mr-2.5 text-gray-400 font-bold"
                            >Protustranka</span
                          >
                        </a>
                      </div>
                    </th>
                    <th class="p-0">
                      <div class="pb-3.5">
                        <a class="inline-flex items-center" href="#">
                          <span class="mr-4 text-gray-400 font-bold"
                            >Nadležno tijelo</span
                          >
                        </a>
                      </div>
                    </th>
                    <th class="p-0">
                      <div class="pb-3.5">
                        <a class="inline-flex items-center" href="#">
                          <span class="mr-4 text-gray-400 font-bold"
                            >Poslovni broj</span
                          >
                        </a>
                      </div>
                    </th>
                    <th class="p-0">
                      <div class="pb-3.5">
                        <a class="inline-flex items-center" href="#">
                          <span class="mr-4 text-gray-400 font-bold"
                            >Vrsta postupka</span
                          >
                        </a>
                      </div>
                    </th>
                    <th class="p-0">
                      <div class="pb-3.5"></div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    class="single-table-row text-gray-400 border-b-2 pr-4 py-6 px-2 hover:cursor-pointer hover:bg-gray-300 hover:bg-opacity-10 rounded-full transition-all duration-300 ease-in-out hover:text-gray-100 hover:shadow-md"
                    v-for="(single_case, key) in paginatedCases"
                    @click="openCase(single_case.case_uri)"
                    :key="key"
                  >
                    <td class="py-6 pr-4 pl-2">
                      <div class="flex flex-wrap items-center">
                        <span class="font-semibold">{{
                          single_case.case_no
                        }}</span>
                      </div>
                    </td>
                    <td class="py-6 pr-4">
                      <span class="font-medium">
                        <div
                          v-if="single_case.counter_parties.length > 0"
                          v-for="(cp, key) in single_case.counter_parties"
                        >
                          {{
                            single_case.counter_parties
                              .map((p) => p.name)
                              .join(",")
                          }}
                        </div>
                        <div v-else>N/A</div>
                      </span>
                    </td>
                    <td class="py-6 pr-4">
                      <span class="font-medium">{{
                        single_case.supervise_body
                      }}</span>
                    </td>
                    <td class="py-6 pr-4">
                      <span class="font-medium">{{ single_case.pbr }}</span>
                    </td>
                    <td class="py-6 pr-4">
                      <span class="font-medium">{{
                        single_case.case_type
                      }}</span>
                    </td>
                    <td
                      class="flex justify-end py-6 pr-4"
                      @click="openCase(single_case.case_uri)"
                    >
                    <span v-if="single_case.deadline" class="px-2.5 py-1 text-sm font-medium text-yellow-500 bg-blue-500 bg-opacity-10 rounded-full hover:bg-none hover:text-blue-300 cursor-pointer">
                      <div>{{ fromNow(single_case.deadline.expiry_date) }}</div>
                    </span>
                      <span
                        class="px-2.5 py-1 text-sm font-medium text-blue-500 bg-blue-500 bg-opacity-10 rounded-full hover:bg-none hover:text-blue-300 cursor-pointer"
                        >Otvori</span
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <PaginationCustom
              :totalItems="list_of_cases.length"
              :perPage="rows_per_page"
              :currentPage="currentPage"
              @pagechanged="onPageChange"
            />
          </div>
        </div>
      </div>
    </section>
    <div class="container"></div>
  </div>
</template>

<script>
import { getDashboardData } from "@/services/dashboard";
import store from "@/store";
import { computed, onMounted, ref } from "vue";
import gsap from "gsap";
import router from "@/router";
import { supabase } from "@/services/supabase";
import moment from "moment";
import { useUserStore } from "@/store/auth.session";
import OnboardingCustom from "@/components/OnboardingCustom.vue";
import PaginationCustom from "@/components/PaginationCustom.vue";
import "v-onboarding/dist/style.css";

// @ is an alias to /src

export default {
  name: "HomeView",

  components: {
    OnboardingCustom,
    PaginationCustom,
  },

  setup() {
    const current_user = useUserStore().user_profile;

    const is_onboarding_visible = ref(false);

    // NUMBER FORMATTER
    const currentPage = ref(1);
    const rows_per_page = ref(5);
    const list_of_cases = ref([]);
    // NUMBER FORMATTER

    const steps = [
      {
        attachTo: { element: "#table-wrapper" },
        content: {
          title: "Popis predmeta",
          description:
            "Odabirom pojedinog predmeta iz Popisa dobivate detaljne informacije i prikaz svih radnji u predmetu.",
        },
      },
    ];

    const dashboardData = ref(null);
    const getAllData = async () => {
      await getDashboardData()
        .then((resp) => {
          dashboardData.value = resp.data;
          list_of_cases.value = resp.data.cases;
        })
        .then(() => {
          // gsap
          //   .from(".table-main", {
          //     duration: 1,
          //     opacity: 0,
          //     x: -100,
          //     delay: 0.5,
          //     ease: "power1.out",
          //     force3D: true,
          //   })
          //   .then(() => {
          is_onboarding_visible.value = true;
          // });
        });
    };
    const paginatedCases = computed(() => {
      const start = (currentPage.value - 1) * rows_per_page.value;
      let end = start + rows_per_page.value;
      if (end > list_of_cases.value.length) {
        end = list_of_cases.value.length;
      }

      return list_of_cases.value.slice(start, end);
    });
    getAllData();
    const returnTimestamp = (dt) => {
      moment().locale("hr");
      return moment(dt).format("DD.MM.YYYY.");
    };
    const returnSpanTimestamp = (start, end) => {
      moment().locale("hr");
      let begin_dt = moment(start).format("DD.MM.YYYY.");
      let end_dt = moment(end).format("DD.MM.YYYY.");
      if (begin_dt == end_dt) {
        return (
          begin_dt +
          moment(start).format(" HH:mm") +
          " - " +
          moment(end).format("HH:mm")
        );
      } else {
        return (
          begin_dt +
          moment(start).format(" HH:mm") +
          " - " +
          end_dt +
          moment(end).format(" HH:mm")
        );
      }
    };
    const isExpired = (dt) => {
      const currentTimestamp = Date.now();
      return currentTimestamp > new Date(dt).getTime();
    };

    const openCase = (case_uid) => {
      router.push("/case/" + case_uid);
    };
    const returnFileType = (file_name) => {
      var idx_ext = file_name.split(".").length - 1;
      var extension = file_name.split(".")[idx_ext].toLowerCase();

      var return_val = require("@/assets/img/doc_icon.svg");
      switch (extension) {
        case "docx":
          return_val = require("@/assets/img/doc_icon.svg");
          break;
        case "dotx":
          return_val = require("@/assets/img/doc_icon.svg");
          break;
        case "dotm":
          return_val = require("@/assets/img/doc_icon.svg");
          break;
        case "pptx":
          return_val = require("@/assets/img/ppt-icon.png");
          break;
        case "ppt":
          return_val = require("@/assets/img/ppt-icon.png");
          break;
        case "doc":
          return_val = require("@/assets/img/doc_icon.svg");
          break;
        case "pdf":
          return_val = require("@/assets/img/pdf_icon.svg");
          break;
        case "csv":
          return_val = require("@/assets/img/xls_icon.svg");
          break;
        case "xlsx":
          return_val = require("@/assets/img/xls_icon.svg");
          break;
        case "jpg":
          return_val = require("@/assets/img/jpg_icon.svg");
          break;
        case "jpeg":
          return_val = require("@/assets/img/jpg_icon.svg");
          break;
        case "png":
          return_val = require("@/assets/img/png_icon.svg");
          break;
        case "folder":
          return_val = require("@/assets/img/folder_yellow.svg");
          break;
        default:
          return_val = require("@/assets/img/dat_icon.svg");
          break;
      }
      return return_val;
    };
    initTooltips();
    const fromNow = (date) => {
      console.log("Date", date);
      return "Ističe " + moment(date).locale("hr").format("LL");
    };
    const getUserAvatar = (user_uid) => {
      console.log(process.env.VUE_APP_GENERAL_API, user_uid);
      return process.env.VUE_APP_GENERAL_API + user_uid + ".jpeg";
    };
    const onPageChange = (page) => {
      console.log(page);

      
      const single_tbl_rows = document.querySelectorAll(".single-table-row");
      console.log(single_tbl_rows);
      if (single_tbl_rows) {
        gsap
          .to(".single-table-row", {
            duration: 0.2,
            x: -100,
            opacity: 0,
            ease: "power1.out",
            // force3D: true,
          })
          setTimeout(() => {
            currentPage.value = page;
          }, 300);

         gsap.to(".single-table-row", {
            duration: 0.2,
            x: 0,
            opacity: 1,
            delay: 0.5,
            ease: "power1.out",
            // force3D: true,
          });
          // .to(
          //   ".single-table-row",
          //   {
          //     duration: 1.5,
          //     x: 0,
          //     opacity: 1,
          //     delay: 1,
          //     ease: "power1.out",
          //     force3D: true,
          //   },
          //   "-=1.5"
          // );
      }
    };

    return {
      current_user,
      dashboardData,
      currentPage,
      paginatedCases,
      list_of_cases,
      rows_per_page,
      openCase,
      returnTimestamp,
      isExpired,
      returnFileType,
      returnSpanTimestamp,
      getUserAvatar,
      onPageChange,
      fromNow,
      steps,
      is_onboarding_visible,
    };
  },
};
</script>
