import { defineStore } from 'pinia'
import { socket } from '../lib/socket.js'
import { useUserStore } from './auth.session.js'
import { successToastMessage } from '@/lib/toastifyNotifications.js'


export const useNotificationStore = defineStore('notification_store', {
    // arrow function recommended for full type inference
    state: () => {
        return {
            notifications: [],
            last_opened_notifications: null,
            count_unopened: 0
        }
    },
    actions: {
        openNotifications() {
            this.last_opened_notifications = new Date()
            socket.emit('CLIENT_PORTAL_OPEN_NOTIFICATION', { date: this.last_opened_notifications })
            this.count_unopened = 0;
        },
        getAllUnreadNotifications() {
            return this.notifications.filter((p) => p.unread == true).length
        },


        getAllUnreadFileNotificatons() {
            return this.notifications.filter((p) => !p.opened ).filter((p)=> p.link.startsWith("/files"))
        },

        getAllUnreadComunicationNotificatons() {
            return this.notifications.filter((p) => !p.opened ).filter((p)=> p.link.startsWith("/comunication"))
        },
        


        /**
         * Push to  array of notication store a single notification object
         * @param {*} notification_obj Single notification created from database
         */
        add_new_notification(notification_obj) {
           
            if (this.notifications.findIndex((p) => p.id == notification_obj.id) < 0) {
                this.notifications.unshift(notification_obj)
                this.count_unopened++;
                successToastMessage(notification_obj.title)
                
            }
        },
        
        /**
         * Push to  array of notication store a single notification object
         * @param {*} notification_obj Single notification created from database
         */
        init_all_notification(notification_obj) {
            this.last_opened_notifications = notification_obj.last_checked_notifications
            this.count_unopened = notification_obj.count_unopened
            
        },

        addNotificationArray(args) {
            this.notifications = this.notifications.concat(args);
            

        },




        userReadSingleNotification(notification_id) {
            //Set logic that is going to mark as read all notifications when opened in Dropdown
            socket.emit('CLIENT_PORTAL_READ_SINGLE_NOTIFICATION', { notification_id : notification_id })
            let found_index = this.notifications.findIndex((p) => p.id == notification_id)
            if (found_index >= 0) this.notifications[found_index].opened = new Date()
        },
        userReadAllNotification() {
            //Set logic that is going to mark as read all notifications when opened in Dropdown
            socket.emit('CLIENT_PORTAL_READ_ALL_NOTIFICATION', { notification_id : useUserStore().user_profile.uid })
            this.notifications.forEach((p) => {
                p.opened = new Date()
            })
        },
        userUnreadSingleNotification(notification_id) {
            //Set logic that is going to mark as read all notifications when opened in Dropdown
            socket.emit('CLIENT_PORTAL_UNREAD_SINGLE_NOTIFICATION', { notification_id : notification_id })
            let found_index = this.notifications.findIndex((p) => p.id == notification_id)
            if (found_index >= 0) this.notifications[found_index].opened = null
        },


        add_client_onboarding_route(route_name){
           
            const user_store = useUserStore();
            const curr_user_id = user_store.user_profile.uid 
             console.log("Adding client onboarding route", route_name, user_store.user_profile)
             user_store.addOnboardingRoute(route_name)
            socket.emit('CLIENT_PORTAL_ONBOARDING_ROUTE_APPEND', {route_name, client_id:curr_user_id})
        }
    }
})
