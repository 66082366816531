<template>
  <div
              class="flex flex-wrap items-center justify-between -m-2 text-white mb-8"
            >
              <div class="w-auto p-2">
                <p class="text-sm text-neutral-500">
                  {{calcNumber()}}
                </p>
              </div>
              <div class="w-auto p-2">
                <div class="flex flex-wrap -m-0.5">
                  <div class="w-auto p-0.5" @click="onClickFirstPage"
                  v-if="!isInFirstPage">
                    <a
                      class="flex items-center justify-center w-9 h-9 border hover:border-neutral-300 rounded-sm"
                      href="#"
                    >
                    <svg
                        width="7"
                        height="12"
                        viewbox="0 0 7 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6 10.6666L1.33333 5.99998L6 1.33331"
                          stroke="#71717A"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                      </svg>
                      <svg
                        width="7"
                        height="12"
                        viewbox="0 0 7 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6 10.6666L1.33333 5.99998L6 1.33331"
                          stroke="#71717A"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                      </svg>
                    </a>
                  </div>
                  <div class="w-auto p-0.5" @click="onClickPreviousPage"
                  v-if="!isInFirstPage">
                    <a
                      class="flex items-center justify-center w-9 h-9 border hover:border-neutral-300 rounded-sm"
                      href="#"
                    >
                      <svg
                        width="7"
                        height="12"
                        viewbox="0 0 7 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6 10.6666L1.33333 5.99998L6 1.33331"
                          stroke="#71717A"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                      </svg>
                    </a>
                  </div>
                  <div class="w-auto p-0.5" v-for="page in pages" :key="page"   @click="onClickPage(page.name)" v-if="pages.length>1">
                    <a
                      class="flex items-center justify-center w-9 h-9 border hover:bg-purple-500  rounded-sm"
                      :class="{ 'bg-purple-500': isPageActive(page.name) }" 
                      href="#"
                    >
                      <span class="text-sm text-neutral-400 font-semibold"
                        >{{page.name}}</span
                      >
                    </a>
                  </div>
                  
                  <div class="w-auto p-0.5" @click="onClickNextPage"
          v-if="!isInLastPage">
                    <a
                      class="flex items-center justify-center w-9 h-9 border hover:border-neutral-300 rounded-sm"
                      href="#"
                    >
                      <svg
                        width="7"
                        height="12"
                        viewbox="0 0 7 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1 1.33335L5.66667 6.00002L1 10.6667"
                          stroke="#71717A"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                      </svg>
                    </a>
                  </div>
                  <div class="w-auto p-0.5" @click="onClickLastPage"
                    v-if="!isInLastPage">
                    <a
                      class="flex items-center justify-center w-9 h-9 border hover:border-neutral-300 rounded-sm"
                      href="#"
                    >
                      <svg
                        width="7"
                        height="12"
                        viewbox="0 0 7 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1 1.33335L5.66667 6.00002L1 10.6667"
                          stroke="#71717A"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                      </svg>
                      <svg
                        width="7"
                        height="12"
                        viewbox="0 0 7 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1 1.33335L5.66667 6.00002L1 10.6667"
                          stroke="#71717A"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
    <!-- <ul class="pagination">
      <li class="pagination-item">
        <button
          type="button"
          @click="onClickFirstPage"
          :disabled="isInFirstPage"
        >
          First
        </button>
      </li>
  
      <li class="pagination-item">
        <button
          type="button"
          @click="onClickPreviousPage"
          :disabled="isInFirstPage"
        >
          Previous
        </button>
      </li> -->
  
      <!-- Visible Buttons Start -->
  
      <!-- <li
        v-for="page in pages"
        class="pagination-item"
      >
        <button
          type="button"
          @click="onClickPage(page.name)"
          :disabled="page.isDisabled"
          :class="{ active: isPageActive(page.name) }"
        >
          {{ page.name }}
        </button>
      </li>
  
     Visible Buttons End 
  
      <li class="pagination-item">
        <button
          type="button"
          @click="onClickNextPage"
          :disabled="isInLastPage"
        >
          Next
        </button>
      </li>
  
      <li class="pagination-item">
        <button
          type="button"
          @click="onClickLastPage"
          :disabled="isInLastPage"
        >
          Last
        </button>
      </li>
    </ul> -->
  </template>

<script>
export default {
  props: {
    // maxVisibleButtons: {
    //   type: Number,
    //   required: false,
    //   default: 3,
    // },
    perPage: {
      type: Number,
      required: true,
    },
    totalItems: {
      type: Number,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      setupMaxVisible : 3,
      // totalPages: Math.ceil(this.totalItems / this.perPage),
    };
  },
  computed: {
    maxVisibleButtons (){
      return (this.setupMaxVisible > this.totalPages) ? this.totalPages : this.setupMaxVisible;
    },
    totalPages() {
      return Math.ceil(this.totalItems / this.perPage);
    },
    startPage() {
      // When on the first page
      if (this.currentPage === 1) {
        return 1;
      }

      // When on the last page
      if (this.currentPage === this.totalPages) {
        let btn_num = this.totalPages - this.maxVisibleButtons + 1;
        if(btn_num <= 1){
          return 1;
        }else{
          return btn_num;
        }
      }

      // When inbetween
      return this.currentPage - 1;
    },
    pages() {
      const range = [];

      for (
        let i = this.startPage;
        i <=
        Math.min(this.startPage + this.maxVisibleButtons - 1, this.totalPages);
        i++
      ) {
        range.push({
          name: i,
          isDisabled: i === this.currentPage,
        });
      }

      return range;
    },
    isInFirstPage() {
      return this.currentPage === 1;
    },
    isInLastPage() {
      return this.currentPage === this.totalPages;
    },
  },

  methods: {
    calcNumber (){
      let start_val = (this.currentPage - 1) * this.perPage + 1;
      let end_val = this.currentPage * this.perPage;
      if(end_val > this.totalItems){
        end_val = this.totalItems;
      }
      return `Prikazujem ${start_val} do ${end_val} od ukupno ${this.totalItems} rezultata`;
    },
    onClickFirstPage() {
      this.$emit('pagechanged', 1);
    },
    onClickPreviousPage() {
      this.$emit('pagechanged', this.currentPage - 1);
    },
    onClickPage(page) {
      this.$emit('pagechanged', page);
    },
    onClickNextPage() {
      this.$emit('pagechanged', this.currentPage + 1);
    },
    onClickLastPage() {
      this.$emit('pagechanged', this.totalPages);
    },
    isPageActive(page) {
      return this.currentPage === page;
    }
  }
};

</script>
