<template>
  <div class="general-wrapp" v-if="caseData != null">
    <CaseNavigation
      :activeTab="4"
      :caseData="caseData"
      @message-sent="getData"
    ></CaseNavigation>
    <div class="mx-auto">
      <section class="container mx-auto">
        <div class="">
          
          <!-- wrapper of questions -->
          <ComunicationTable
            :all_comunication="all_comunication"
            :key="keysData"
          />
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import router from "@/router";
import gsap from "gsap";


import { getMyCaseMessages } from "@/services/comunication.user";
import { getSingleCaseOverview } from "@/services/case.service";

import ComunicationTable from "@/components/Tables/ComunicationTable.vue";
import CaseNavigation from "@/components/Navigation/CaseNavigation.vue";

import { ref } from "vue";

export default {
  name: "GeneralComunictioanView",
  components: {
    ComunicationTable,
    CaseNavigation
  },
  setup() {
    const all_comunication = ref([]);
    const caseData = ref(null);
    const keysData = ref(0);

    const case_id = ref(router.currentRoute.value.params.uid);
    console.log(case_id.value);
    

    const getData = async () => {
      
      await getMyCaseMessages(case_id.value).then((response) => {
        console.log("My messages", response);
        all_comunication.value = response.data;
        keysData.value++
      });

      await getSingleCaseOverview(case_id.value).then((resp) => {
        caseData.value = resp.data;

        gsap.from(".general-wrapp", {
          duration: 1,
          x: -100,
          opacity: 0,
          ease: "power1.out",
          force3D: true,
        });

        console.log(caseData.value);
      });
    };

    getData();
    return {
      all_comunication,
      caseData,
      keysData,
      getData
    };
  },
};
</script>
