<template>
  <div class="z-40">
    <section class="container mx-auto">
      <div class="" v-if="all_comunication.length > 0">
        <!-- wrapper of questions -->
        <div class="flex">
          <div class="">
            <div class="w-auto mb-4">
              <div class="relative inline-block rounded-xl">
                <select
                  v-model="selectedTimeOrder"
                  @change="handleChange"
                  class="relative py-2 pl-6 pr-8 cursor-pointer text-white font-semibold appearance-none outline-none rounded-full bg-transparent border-2 border-gray-500"
                >
                  <option value="newToOld">Noviji - Stariji</option>
                  <option value="oldToNew">Stariji - Noviji</option>
                </select>
              </div>
            </div>
          </div>
          <div class="">
            <div class="w-auto mb-4 ml-2">
              <div class="relative inline-block rounded-xl">
                <select
                  v-model="selectedAnsweared"
                  @change="handleChange"
                  class="relative py-2 pl-6 pr-8 cursor-pointer text-white font-semibold appearance-none outline-none rounded-full bg-transparent border-2 border-gray-500"
                >
                  <option value="all">Svi upiti</option>
                  <option value="unansweared">Neodgovoreni</option>
                  <option value="answeared">Odgovoreni</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="container mx-auto text-gray-100 mt-2">
          <div
            class="w-full py-4 border-b-2 border-dotted flex flex-row flex-justify items-center justify-center text-xl"
          >
            <div class="w-full md:w-2/5 p-2 ml-3">
              <div>Naslov</div>
            </div>
            <div class="w-full md:w-3/5 p-2">Upit / Odgovor</div>
          </div>
        </div>
        <div class="table-wrapp-single">
          <div
            class="container mx-auto text-gray-100"
            v-for="(single_q, key) in paginated_results"
            :key="key"
          >
            <div
              class="w-full py-4 border-b-2 flex flex-row flex-justify items-center justify-center"
            >
              <div class="w-full md:w-2/5 p-2 ml-3">
                <div>{{ single_q.title }}</div>
                <div class="text-sm mt-2 text-gray-600">
                  {{ getRemainderHours(single_q.question_ts) }}
                </div>
                <div class="py-2" v-if="single_q.answear_ts">
                  Odgovor od: {{ single_q.odvjetnik.ime }}
                  {{ single_q.odvjetnik.prezime }}
                  {{ getRemainderHours(single_q.answear_ts) }}
                </div>
              </div>
              <div class="w-full md:w-3/5">
                <div class="flex flex-row flex-justify text-left">
                  <div class="text-gray-100 text-lg pr-4">Pitanje:</div>
                  <div class="p-2">
                    {{ single_q.question }}
                  </div>
                </div>

                <div class="flex flex-row flex-justify">
                  <div class="text-green-600 text-lg" v-if="single_q.answear">
                    Odgovor:
                  </div>
                  <div class="p-2" v-if="single_q.answear">
                    {{ single_q.answear }}
                  </div>
                  <div class="underline" v-else>Upit još nema odgovor.</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="my-4">
          <PaginationCustom
            :totalItems="filtered_q_and_a.length"
            :perPage="rows_per_page"
            :currentPage="currentPage"
            @pagechanged="onPageChange"
          />
        </div>
      </div>
      <div v-else class="text-white text-lg">
        <span>Za sada nema upućenih upita. </span>
      </div>
    </section>
  </div>
</template>

<script>
import moment from "moment";
import { computed, onMounted, ref } from "vue";
import PaginationCustom from "@/components/PaginationCustom.vue";
import gsap from "gsap";
import { all } from "axios";
import { readMessages } from "@/services/comunication.user";

export default {
  props: {
    all_comunication: {
      type: Array,
      required: true,
    },
  },
  components: {
    PaginationCustom,
  },

  setup(props) {
    const currentPage = ref(1);
    const rows_per_page = ref(4);
    const selectedTimeOrder = ref("newToOld"); // Default order
    const selectedAnsweared = ref("all"); // Default order
    const filtered_q_and_a = ref(props.all_comunication);
    const all_comunication = ref(props.all_comunication);
    const sender_read_arr = ref([]);

    const getRemainderHours = (ts_obj) => {
      moment.locale("hr");
      return "(prije " + moment(ts_obj).fromNow(ts_obj) + ")";
    };

    const paginated_results = computed(() => {
      const start = (currentPage.value - 1) * rows_per_page.value;

      let end = start + rows_per_page.value;
      if (end > filtered_q_and_a.value.length) {
        end = filtered_q_and_a.value.length;
      }
      const final = filtered_q_and_a.value.slice(
        (currentPage.value - 1) * rows_per_page.value,
        currentPage.value * rows_per_page.value
      );
      let unread_res = final
        .filter((p) => p.answear_ts != null && p.sender_read == null)
        .map((p) => p.uid);
      let final_unread = [];
      unread_res.forEach((uid) => {
        if (!sender_read_arr.value.includes(uid)) {
          sender_read_arr.value.push(uid);
          final_unread.push(uid);
        }
      });
      console.log(unread_res, final_unread, "final computed");
      if (final_unread.length > 0) {
        readMessages(final_unread);
      }
      return final;
    });
    const handleChange = () => {
      let answeared = all_comunication.value.filter((single_q) => {
        return single_q.answear_ts !== null;
      });
      let unansweared = all_comunication.value.filter((single_q) => {
        return single_q.answear_ts === null;
      });
      console.log(answeared.length, unansweared.length);

      if (selectedTimeOrder.value === "newToOld") {
        unansweared.sort(
          (a, b) => new Date(b.question_ts) - new Date(a.question_ts)
        );
        answeared.sort(
          (a, b) => new Date(b.answear_ts) - new Date(a.answear_ts)
        );
      } else {
        unansweared.sort(
          (a, b) => new Date(a.question_ts) - new Date(b.question_ts)
        );
        answeared.sort(
          (a, b) => new Date(a.answear_ts) - new Date(b.answear_ts)
        );
      }

      if (selectedAnsweared.value === "all") {
        filtered_q_and_a.value = [];
        filtered_q_and_a.value = [...answeared, ...unansweared];
      } else if (selectedAnsweared.value === "unansweared") {
        filtered_q_and_a.value = [...unansweared];
      } else if (selectedAnsweared.value === "answeared") {
        filtered_q_and_a.value = [...answeared];
      }
      onPageChange(1);
    };
    const onPageChange = (page) => {
      console.log(page);
      gsap.to(".table-wrapp-single", {
        duration: 0.2,
        x: -100,
        opacity: 0,
        ease: "power1.out",
        // force3D: true,
      });
      setTimeout(() => {
        currentPage.value = page;
      }, 400);

      gsap.to(".table-wrapp-single", {
        duration: 0.5,
        x: 0,
        opacity: 1,
        delay: 0.5,
        ease: "power1.out",
        force3D: true,
      });
    };
    onMounted(() => {
      handleChange();
    });
    return {
      all_comunication,
      filtered_q_and_a,
      selectedTimeOrder,
      selectedAnsweared,
      currentPage,
      rows_per_page,
      sender_read_arr,
      getRemainderHours,
      handleChange,
      onPageChange,
      paginated_results,
    };
  },
};
</script>
