<template>
  <div class="mx-auto">
    <section class="container mx-auto">
      <div class="">
        <div class="text-4xl text-gray-100 pt-18 mb-2">
          <span class="border-b-4">Komunikacija</span>
        </div>
        <div class="mb-14 text-gray-400">
          <span>Svi vaši upiti na jednom mjestu.</span>
        </div>
        <!-- wrapper of questions -->
        <ComunicationTable :all_comunication="all_comunication" :key="all_comunication.length"/>

      
     
      </div>
    </section>
  </div>
</template>

<script>
import { getMyMessages } from "@/services/comunication.user";
import moment from "moment";
import ComunicationTable from "@/components/Tables/ComunicationTable.vue";
import { ref } from "vue";

export default {
  name: "GeneralComunictioanView",
  components: {
    ComunicationTable,
  },
  setup() {

    const all_comunication = ref([]);

    const getData = async () => {
      await getMyMessages().then((response) => {
        console.log(response);
        all_comunication.value = response.data;
      });
    };


    getData();
    return {
      all_comunication,

    };
  },
};
</script>
