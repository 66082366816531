<template>
  <div class="z-90">
    <button
      :data-modal-target="'file-details-modal'+file.id"
      :data-modal-toggle="'file-details-modal'+file.id"
      class="flex px-6 py-1 items-center justify-center bg-gray-600 hover:bg-gray-700 text-sm leading-6 font-bold text-white rounded-xl mx-auto"
      type="button"
    >
      Detalji
    </button>

    <div
      :id="'file-details-modal'+file.id"
      tabindex="-1"
      style="z-index: 100000"
      class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-90 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full backdrop-blur-sm"
    >
      <div>
        <div
          class="max-w-xl w-full mx-auto p-6 bg-custom-container rounded-xl text-justify"
        >
          <div class="relative">
            <div class="absolute top-0 right-0 -mt-2 flex items-center">
              <button
                class="flex mr-2 items-center justify-center w-8 h-8 rounded-lg close-btn"
                :data-modal-toggle="'file-details-modal'+file.id"
              >
                <svg
                  class="close-svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    <circle cx="12" cy="12" r="10" stroke-width="1.5"></circle>
                    <path
                      d="M14.5 9.50002L9.5 14.5M9.49998 9.5L14.5 14.5"
                      stroke-width="1.5"
                      stroke-linecap="round"
                    ></path>
                  </g>
                </svg>
              </button>
            </div>
            <div class="absolute top-0 right-0 mt-2 flex items-center"></div>
            <div></div>
            <div class="mb-2">
              <span
                class="text-sm font-semibold text-gray-400"
                contenteditable="false"
                >Detalji dokumenta</span
              >
            </div>
            <h4
              class="text-lg font-semibold text-gray-200 mb-6"
              contenteditable="false"
            >
              {{ file.file_name }}
            </h4>
            <div class="pb-8 mb-8 border-b border-gray-400">
              <div class="flex mb-6 items-center">
                <span
                  class="block w-24 text-sm font-semibold text-gray-400"
                  contenteditable="false"
                  >Podijelio</span
                >
                <div class="flex items-center">
                  <img
                    class="w-8 h-8 rounded-full mr-2"
                    :src="getUserAvatar(file.odvjetnik.uid)"
                    alt=""
                  />
                  <span class="text-sm font-semibold text-gray-200"
                    >{{ file.odvjetnik.ime }} {{ file.odvjetnik.prezime }}</span
                  >
                </div>
              </div>
              <div class="flex mb-6 items-center">
                <span
                  class="block w-24 text-sm font-semibold text-gray-400"
                  contenteditable="false"
                  >Dijeljeno</span
                >
                <span class="text-sm font-semibold text-gray-200">
                  {{ returnTimestamp(file.created_at) }}</span
                >
              </div>
              <div class="flex mb-6 items-center">
                <span
                  class="block w-24 text-sm font-semibold text-gray-400"
                  contenteditable="false"
                  >Ističe</span
                >
                <span class="text-sm font-semibold text-gray-200">
                  {{ returnTimestamp(file.expiration_date_time) }}</span
                >
              </div>
              <div class="flex items-center">
                <span
                  class="block w-24 text-sm font-semibold text-gray-400"
                  contenteditable="false"
                  >Predmet</span
                >
                <span
                  class="inline-block py-1 px-2 mr-3 text-sm font-medium text-green-500 bg-green-800 bg-opacity-80 rounded-full"
                  contenteditable="false"
                  >{{ file.predmet.ime_predmeta }}</span
                >
              </div>
            </div>
            <div class="pb-8 mb-8 border-b border-gray-400">
              <span
                class="block mb-2 text-sm font-semibold text-gray-400"
                contenteditable="false"
                >Opis dijeljenja</span
              >
              <div
                class="text-gray-300"
                contenteditable="false"
                v-html="
                  file.description ? file.description : 'Nema dodatnog opisa'
                "
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { initModals } from "flowbite";
import { onMounted } from "vue";

export default {
  props: {
    file: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    console.log("Modal file opened",props.file);

    const returnTimestamp = (dt) => {
      moment().locale("hr");
      return moment(dt).format("DD.MM.YYYY.");
    };

    const isExpired = (dt) => {
      const currentTimestamp = Date.now();
      return currentTimestamp > new Date(dt).getTime();
    };
    const getUserAvatar = (user_uid) => {
      return process.env.VUE_APP_GENERAL_API + user_uid + ".jpeg";
    };
    onMounted(() => {
      initModals();
    });
    return {
      file: props.file,
      returnTimestamp,
      isExpired,
      getUserAvatar,
    };
  },
};
</script>
