import axios from 'axios';
import authHeader from './auth-header';
import { socket } from '@/lib/socket';

const API_URL = process.env.VUE_APP_API_ENDPOINT_STARTER;

const getMyCaseMessages = async (uid)=> {
    return axios.get(API_URL + '/comunication/case/'+uid, { headers: await authHeader() });
  }

  const getMyMessages = async (uid)=> {
    return axios.get(API_URL + '/comunication/', { headers: await authHeader() });
  }

  const createNewMessage = async (case_id, message_object)=> {
    return axios.post(API_URL + '/comunication/case/'+case_id, {
        message_object
    }, { headers: await authHeader() });
  }


  const readMessages = async(msg_arr)=>{
    socket.emit("CLIENT_PORTAL_READ_MESSAGES", msg_arr);
  }

export{
    getMyCaseMessages,
    getMyMessages,
    createNewMessage,
    readMessages
}
