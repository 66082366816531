<template>
  <div class="mx-auto">
    <section class="container px-4 mx-auto">
      <div class="pt-8 pb-2">
        <div class="pb-6 border-b-2 border-gray-500">
          <h4
            class="text-2xl font-bold text-white tracking-wide leading-6 mb-1"
          >
            Ročišta
          </h4>

          <p
            class="text-xs leading-5 text-gray-300"
            contenteditable="false"
          ></p>
        </div>
      </div>
    </section>

    <section class="py-3">
      <div class="container px-4 mx-auto">
        <div class="flex flex-wrap -mx-3 mb-6">
          <div class="w-full px-3">
            <div class="h-full rounded-xl">
              <div class="flex flex-wrap -mx-3 -mb-6" v-if="all_rocista.length > 0">
                <div
                  class="w-full md:w-1/2 lg:w-1/4 px-3 mb-6 single-stag"
                  v-for="(single_rociste, key) in all_rocista"
                  :key="key"
                >
                  <div
                    class="relative text-center bg-custom-container rounded-xl h-72 flex flex-col justify-center items-center px-4"
                  >
                    <h4 class="text-white font-bold mb-3 text-lg">
                      {{ single_rociste.predmet.broj_predmeta }} -
                      {{ single_rociste.title }}
                    </h4>
                    <p class="text-gray-300 mb-3 text-sm w-full text-left">
                      <span class="rounded-full bg-gray-100 text-black px-1"
                        >Predmet:</span
                      >
                      <a
                        :href="'/case/' + single_rociste.predmet.group_url"
                        class="ml-3 hover:underline"
                      >
                        {{ single_rociste.predmet.broj_predmeta }}</a
                      >
                    </p>
                    <p class="text-gray-300 mb-3 text-sm w-full text-left">
                      <span class="rounded-full bg-gray-100 text-black px-1"
                        >Datum:</span
                      >
                      <span class="ml-5">
                        {{
                          returnSpanTimestamp(
                            single_rociste.start_date_time,
                            single_rociste.end_date_time
                          )
                        }}</span
                      >
                    </p>

                    <p class="text-gray-300 text-sm mx-2 w-full text-left">
                      <span class="rounded-full bg-gray-100 text-black px-1"
                        >Lokacija:</span
                      >
                      <span class="ml-2" v-if="single_rociste.location_address">
                        {{ single_rociste.location_address }}
                      </span>
                      <span class="ml-2"> N/A </span>
                    </p>
                    <div class="flex -space-x-1 overflow-hidden mt-5">
                      <div class="group-avatar flex justify-center items-center" v-for="(single_lawyer,key) in single_rociste.event_lawyer_connector" :key="key">
                        <img
                          :data-tooltip-target="
                            single_lawyer.odvjetnik.uid +
                            '-t-' +
                            single_rociste.uid
                          "
                          data-tooltip-placement="right"
                          :key="single_lawyer.odvjetnik.uid"
                          class="inline-block h-10 w-10 rounded-full trans"
                          :src="getUserAvatar(single_lawyer.odvjetnik.uid)"
                          alt=""
                        />
                        <div
                          :id="
                            single_lawyer.odvjetnik.uid +
                            '-t-' +
                            single_rociste.uid
                          "
                          role="tooltip"
                          class="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700"
                        >
                          {{ single_lawyer.odvjetnik.ime }}
                          {{ single_lawyer.odvjetnik.prezime }}
                          <!-- <div class="tooltip-arrow" data-popper-arrow></div> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-xl text-white" v-else>Nema dostupnih ročišta.</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { getDashboardData } from "@/services/dashboard";
import store from "@/store";
import { onMounted, ref, watch } from "vue";
import gsap from "gsap";
import router from "@/router";
import { supabase } from "../services/supabase";
import moment from "moment";
import { useUserStore } from "@/store/auth.session";
import { getCalendarData } from "@/services/calendar.service";
import { initTooltips } from "flowbite";
import { all } from "axios";

// @ is an alias to /src

export default {
  name: "HomeView",

  setup() {
    onMounted(() => {
      //   initTooltips();
    });
    const all_rocista = ref([]);
    const getAllData = async () => {
      await getCalendarData()
        .then((resp) => {
          all_rocista.value = resp.data;
        })
        .then(() => {
          gsap.from(".single-stag", {
            duration: 1.5,
            scale: 0.7,
            opacity: 0,
            delay: 0.3,
            stagger: 0.2,
            ease: "power1.out",
            force3D: true,
          });

          gsap.from(".table-main", {
            duration: 1,
            opacity: 0,
            x: -100,
            delay: 1.5,
            ease: "power1.out",
            force3D: true,
          });
          initTooltips();
        });
    };
    getAllData();

    const returnSpanTimestamp = (start, end) => {
      if (!start || !end) {
        return "N/A";
      }
      try {
        moment().locale("hr");
        let begin_dt = moment(start).format("DD.MM.YYYY.");
        let end_dt = moment(end).format("DD.MM.YYYY.");
        if (begin_dt == end_dt) {
          return (
            begin_dt +
            moment(start).format(" HH:mm") +
            " - " +
            moment(end).format("HH:mm")
          );
        } else {
          return (
            begin_dt +
            moment(start).format(" HH:mm") +
            " - " +
            end_dt +
            moment(end).format(" HH:mm")
          );
        }
      } catch {
        return "N/A";
      }
    };

    const openCase = (case_uid) => {
      router.push("/case/" + case_uid);
    };

    const getUserAvatar = (user_uid) => {
      console.log(process.env.VUE_APP_GENERAL_API, user_uid);
      return process.env.VUE_APP_GENERAL_API + user_uid + ".jpeg";
    };

    return {
      all_rocista,
      openCase,
      returnSpanTimestamp,
      getUserAvatar,
    };
  },
};
</script>
