import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.VUE_APP_API_ENDPOINT_STARTER;

const getSingleCaseOverview = async (uid)=> {
    return axios.get(API_URL + 'case/'+uid, { headers: await authHeader() });
  }

  const mergeCaseFiles = async (uid)=> {
    return axios.post(API_URL + 'case/'+uid+"/merge-files",{
    },
     { headers: await authHeader(),
      responseType: "arraybuffer",
      encodeURI: "UTF-8",
      credentials: true
     });
  }

export{
    getSingleCaseOverview,
    mergeCaseFiles
}


