import { ref, watch } from "vue";
import { defineStore } from "pinia";
import { supabase } from "@/services/supabase";

export const useUserStore = defineStore("user", () => {
  const user_info = ref(null); //needs to be null in order for App.vue to register if it has some value
  const user_profile = ref(null);

  const loginUser = async (email, otp) => {
    console.log("User email", email, otp);
    const { data, error } = await supabase.auth.verifyOtp({
      email : email,
      token : otp,
      type: "email",
    });
    if (error) {
      console.log("Error", error);
      return {
        status: 400,
        message:
          "Pogrešan kod. Molimo provjerite jeste li unijeli ispravnu jednokratnu zaporku.",
      };
    } else {
      return {
        status: 200
      };
    }
  };

  const logoutUser = async () => {
    await supabase.auth.signOut();
    location.reload();
  };


  const addOnboardingRoute = (route)=>{
    console.log("Adding route", route)
    user_profile.value.onboarding_routes.push(route)
  }

  return { user_info, user_profile, loginUser, logoutUser,addOnboardingRoute };
});
