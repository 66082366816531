<template>
  <div class="mx-auto">
    <section class="container  mx-auto">
      <div class="pt-8 pb-2">
        <div class="pb-6 border-b-2 border-gray-500">
          <h4
            class="text-2xl font-bold text-white tracking-wide leading-6 mb-1"
          >
            Svi dijeljeni dokumenti
          </h4>

          <p
            class="text-xs leading-5 text-gray-300"
            contenteditable="false"
            
          > </p>
        </div>
      </div>
    </section>
    
    <div class="container mx-auto mt-4">
        <section class="flex items-center justify-items-stretch">
          <input
            v-model="searchTerm"
            @input="handleSearch"
            class="w-1/3 p-4 text-sm font-medium text-gray-300 placeholder-gray-400 bg-transparent outline-none border-2 border-gray-500"
            id="pageHeaderInput5-1"
            type="search"
            placeholder="Pretraži dokumente..."
          />

          <div class="flex flex-justify ml-4 items-center flex-1">
            <div class="">
              <span
                class="inline-block py-2 px-4 mb-3 mr-3 text-gray-300 leading-6 bg-custom-container rounded-full"
                >Svi dokumenti</span
              >
            </div>
          </div>
          <div class="">
            <div class="w-auto px-3 mb-4">
              <div class="relative inline-block rounded-xl">
                <select
          v-model="selectedOrder"
          @change="handleOrderChange"
          class="relative py-2 pl-6 pr-8 cursor-pointer text-white font-semibold appearance-none outline-none rounded-full bg-transparent border-2 border-gray-500"
          style="z-index: 1;"
        >
          <option value="newToOld">Noviji - Stariji</option>
          <option value="oldToNew">Stariji - Noviji</option>
        </select><span
                  class="absolute top-1/2 right-0 mr-2 transform -translate-y-1/2"
                  style="z-index: 0"
                >
                  <svg
                    width="10"
                    height="7"
                    viewbox="0 0 10 7"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 1.5L5 5.5L9 1.5"
                      stroke="#3D485B"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    </svg></span>
              </div>
            </div>
          </div>
        </section>
      </div>
      <section class="py-3">
          <div class="w-full flex justify-center">
            <div @click="getAllData()"  class="button flex justify-center items-center mx-auto border text-white border-white px-3.5 text-lg rounded-full py-2 hover:bg-white hover:text-blue-800 cursor-pointer">
              <svg id="rotate-svg" class="w-5 h-5 stroke-white text-white " :class="[is_data_loading ? 'animate-spin' : '']" viewBox="-3 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path fill-rule="evenodd" clip-rule="evenodd" d="M9.427 5.01281L10.7071 6.29289C11.0976 6.68342 11.0976 7.31658 10.7071 7.70711C10.3166 8.09763 9.6834 8.09763 9.2929 7.70711L6.29289 4.70711C5.90237 4.31658 5.90237 3.68342 6.29289 3.29289L9.2929 0.292893C9.6834 -0.0976311 10.3166 -0.0976311 10.7071 0.292893C11.0976 0.683418 11.0976 1.31658 10.7071 1.70711L9.4053 3.00896C14.1877 3.22089 18 7.16525 18 12C18 14.3981 17.0565 16.6496 15.4053 18.3224C15.0173 18.7154 14.3841 18.7195 13.9911 18.3315C13.598 17.9436 13.5939 17.3104 13.9819 16.9174C15.2673 15.6152 16 13.8668 16 12C16 8.2774 13.0942 5.23349 9.427 5.01281zM8.5947 20.991C3.81226 20.7791 0 16.8348 0 12C0 9.59706 0.94734 7.34135 2.60451 5.66773C2.9931 5.27528 3.62625 5.27215 4.0187 5.66074C4.41115 6.04933 4.41428 6.68249 4.02569 7.07494C2.73567 8.37777 2 10.1295 2 12C2 15.7226 4.90584 18.7665 8.573 18.9872L7.2929 17.7071C6.90237 17.3166 6.90237 16.6834 7.2929 16.2929C7.6834 15.9024 8.3166 15.9024 8.7071 16.2929L11.7071 19.2929C12.0976 19.6834 12.0976 20.3166 11.7071 20.7071L8.7071 23.7071C8.3166 24.0976 7.6834 24.0976 7.2929 23.7071C6.90237 23.3166 6.90237 22.6834 7.2929 22.2929L8.5947 20.991z" fill="#758CA3"></path></g></svg>
              <span class="pl-2" >Sinkroniziraj</span>
            </div>
        </div>
      </section>
    <section class="py-3">
          <div>
            <section class="py-8">
              <div class="container mx-auto ">

                <div class="flex flex-wrap -mx-5 -mb-6" :key="inc" v-if="filtered_files.length > 0">
                  <SingleFile
                    
                    v-for="(file, key) in filtered_files"
                    :key="key"
                    :file="file"
                    :canShowCase="true"
                    :showDetails="true"
                  ></SingleFile>
                </div>
                <div v-else class="text-white text-lg">Nema dijeljenih dokumenata</div>

              </div>
            </section>
          </div>

    </section>
  </div>
</template>

<script>
import gsap from "gsap";
import { ref } from "vue";
import SingleFile from "@/components/SingleFile.vue";
import { getFileData } from "@/services/file.service";
// @ is an alias to /src

export default {
  name: "AllFiles",
  components: {
    SingleFile,
  },

  setup() {
    const all_files = ref([]);
    const filtered_files = ref([]);
    const searchTerm = ref("");
    const selectedOrder = ref("newToOld"); // Default order
    const inc = ref(0)
    const is_data_loading = ref(false)

    const getAllData = async () => {
      is_data_loading.value = true
      // gsap.to("#rotate-svg",{duration:1,rotation:360,repeat:3, ease:"power1.out"})
      await getFileData().then((resp) => {
        all_files.value = resp.data;
        filtered_files.value = resp.data;
        setTimeout(() => {
          is_data_loading.value = false
        },1000)
        // setTimeout(() => {
          // gsap.from(".single-stag", {
          //   duration: 1.5,
          //   scale: 0.7,
          //   opacity: 0,
          //   delay: 0.3,
          //   stagger: 0.2,
          //   ease: "power1.out",
          //   force3D: true,
          // });

        // }, 50);
      });
    };

    getAllData();

    const handleOrderChange = () => {
      // You can perform the sorting here based on the selected order
      // For example, using JavaScript's sort method

      console.log("Selected order", selectedOrder.value);
      if (selectedOrder.value === "newToOld") {
        filtered_files.value = all_files.value.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );
      } else if (selectedOrder.value === "oldToNew") {
        filtered_files.value = all_files.value.sort(
          (a, b) => new Date(a.created_at) - new Date(b.created_at)
        );
      }
      inc.value += 1;
    };

    const handleSearch = () => {
      // You can perform the filtering here based on the searchTerm
      // For example, using JavaScript's filter method
      filtered_files.value = all_files.value.filter((file) =>
        file.file_name.toLowerCase().includes(searchTerm.value.toLowerCase())
      );
    };
    const clearSearch = () => {
      searchTerm.value = "";
      // Reset the files to the original list when search is cleared
      files.value = caseData.value.shared_files;
    };
   

    return {
      all_files,
      is_data_loading,
      filtered_files,
        searchTerm,
        filtered_files,
        selectedOrder,
        inc,
        handleOrderChange,
        handleSearch,
      getAllData,
      clearSearch
    };
  },
};
</script>
