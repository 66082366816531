<template>
  <div class="z-50">
    <button
      id="dropdownNotificationButton"
      data-dropdown-toggle="dropdownNotification"
      data-dropdown-placement="bottom"
      data-dropdown-offset-distance="10"
      data-dropdown-offset-skidding="-145"     
      class="relative inline-flex items-center text-sm font-medium text-center text-gray-500 hover:text-white focus:outline-none focus:text-white dark:hover:text-white dark:text-gray-400"
      type="button"
    >
      <svg
        class="w-12 h-8"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox="0 0 14 20"
      >
        <path
          d="M12.133 10.632v-1.8A5.406 5.406 0 0 0 7.979 3.57.946.946 0 0 0 8 3.464V1.1a1 1 0 0 0-2 0v2.364a.946.946 0 0 0 .021.106 5.406 5.406 0 0 0-4.154 5.262v1.8C1.867 13.018 0 13.614 0 14.807 0 15.4 0 16 .538 16h12.924C14 16 14 15.4 14 14.807c0-1.193-1.867-1.789-1.867-4.175ZM3.823 17a3.453 3.453 0 0 0 6.354 0H3.823Z"
        />
      </svg>

      <div
        class="absolute block w-3 h-3 bg-green-500 border-2 border-white rounded-full top-0.5 start-2 dark:border-gray-900"
        v-if="has_unopened_notifications"
      ></div>
    </button>

    <!-- Dropdown menu -->
    <div
      id="dropdownNotification"
      class="z-90 hidden mr-12 max-w-sm bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-800 dark:divide-gray-700 w-72"
      aria-labelledby="dropdownNotificationButton"
      style=""
    >
      <div
        class="block px-4 py-2 font-medium text-center text-gray-700 rounded-t-lg bg-gray-50 dark:bg-gray-800 dark:text-white"
      >
        Obavijesti 
      </div>
      <div class="text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
    <ul class="flex flex-wrap -mb-px w-full ">
        <li class="w-1/2 py-2 " :class="[active_notification_type == 0 ?  'border-b-2  border-blue-600' : '']" @click="active_notification_type = 0">
            <a href="#" class=" pb-2 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 ">Nepročitano <span>{{ getUnopenedNotifications() }}</span></a>
        </li>
        <li class="w-1/2 py-2" :class="[active_notification_type == 1 ?  'border-b-2  border-blue-600' : '']" @click="active_notification_type = 1">
            <a href="#" class="pb-2 text-center   rounded-t-lg text-blue-600" aria-current="page">Otvoreno</a>
        </li>
     
    </ul>
</div>
      <ul class="h-80 py-2 overflow-y-auto text-gray-700 dark:text-gray-200 overflow-x-hidden" aria-labelledby="dropdownUsersButton" v-if="filtered_list.length > 0" :key="filtered_list.length">
    <li v-for="(single_not, key) in filtered_list"
          :key="key" :id="'notif-'+single_not.id">

      <router-link
            :to="single_not.link"
            @click="openNotification(single_not)"
            class="flex w-full px-4 py-3 hover:bg-gray-100 dark:hover:bg-gray-700"
          >
            <div class="flex-shrink-0">
              <svg
              v-if="single_not.link.startsWith('/comunication')"
                fill="#000000"
                height="22"
                width="22"
                version="1.1"
                id="Capa_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                viewBox="0 0 489.7 489.7"
                xml:space="preserve"
              >
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  <g>
                    <g>
                      <path
                        d="M0,244.9c0,119.2,109.9,216.2,244.9,216.2c44.7,0,88.2-10.7,126.3-31c19.9,10.5,42.5,15.8,67.3,15.8 c7.8,0,15.6-0.5,23.3-1.6c7.7-1.1,13.8-6.7,15.6-14.3c1.7-7.7-1.4-15.4-8.1-19.8c-12.7-8.4-22.5-20-29.3-34.8 c32.1-37.4,49.7-83.5,49.7-130.6c0-119.2-109.9-216.2-244.9-216.2S0,125.7,0,244.9z M24.5,244.9c0-105.7,98.9-191.7,220.4-191.7 s220.4,86,220.4,191.7c0,44.1-16.8,85.5-48.5,119.9c-3.1,3.4-4.1,8.2-2.5,12.6c6.6,17.7,16.4,32.5,29.3,44 c-24.7,0.9-47.2-4.5-66-15.8c-1.9-1.2-4.1-1.7-6.3-1.7c-2.1,0-4.2,0.5-6.1,1.6c-35.9,20.4-77.5,31.2-120.3,31.2 C123.4,436.6,24.5,350.6,24.5,244.9z"
                      ></path>
                      <circle cx="245" cy="244.9" r="15.7"></circle>
                      <circle cx="312.6" cy="244.9" r="15.7"></circle>
                      <circle cx="177.4" cy="244.9" r="15.7"></circle>
                    </g>
                  </g>
                </g>
              </svg>
              <svg  v-if="single_not.link.startsWith('/files')"
                fill="#000000"
                height="22"
                width="22" version="1.0" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 64 64" enable-background="new 0 0 64 64" xml:space="preserve"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <path fill="#231F20" d="M56,0H8C5.789,0,4,1.789,4,4v56c0,2.211,1.789,4,4,4h48c2.211,0,4-1.789,4-4V4C60,1.789,58.211,0,56,0z M58,60c0,1.104-0.896,2-2,2H8c-1.104,0-2-0.896-2-2V4c0-1.104,0.896-2,2-2h48c1.104,0,2,0.896,2,2V60z"></path> <path fill="#231F20" d="M49,25H15c-0.553,0-1,0.447-1,1s0.447,1,1,1h34c0.553,0,1-0.447,1-1S49.553,25,49,25z"></path> <path fill="#231F20" d="M49,19H15c-0.553,0-1,0.447-1,1s0.447,1,1,1h34c0.553,0,1-0.447,1-1S49.553,19,49,19z"></path> <path fill="#231F20" d="M49,37H15c-0.553,0-1,0.447-1,1s0.447,1,1,1h34c0.553,0,1-0.447,1-1S49.553,37,49,37z"></path> <path fill="#231F20" d="M49,43H15c-0.553,0-1,0.447-1,1s0.447,1,1,1h34c0.553,0,1-0.447,1-1S49.553,43,49,43z"></path> <path fill="#231F20" d="M49,49H15c-0.553,0-1,0.447-1,1s0.447,1,1,1h34c0.553,0,1-0.447,1-1S49.553,49,49,49z"></path> <path fill="#231F20" d="M49,31H15c-0.553,0-1,0.447-1,1s0.447,1,1,1h34c0.553,0,1-0.447,1-1S49.553,31,49,31z"></path> <path fill="#231F20" d="M15,15h16c0.553,0,1-0.447,1-1s-0.447-1-1-1H15c-0.553,0-1,0.447-1,1S14.447,15,15,15z"></path> </g> </g></svg>
            </div>
            <div class="w-full pl-2">
              <div class="text-gray-500 text-sm mb-1.5 dark:text-gray-400">
                <span class="font-semibold text-gray-900 dark:text-white"
                  >{{getNotificationGeneralTitle(single_not.link)}}</span
                >
                <br />
                {{ single_not.title }}
              </div>
              <div class="text-xs text-blue-600 dark:text-blue-500">
                {{ fromWhenIsNotification(single_not.created) }}
              </div>
              
            </div>
      </router-link>
      <div class="text-gray-800 underline text-xs float-right pr-2 cursor-pointer" v-if="single_not.opened" @click="unopenNotification(single_not)">
                Označi kao nepročitano
              </div>
      
    </li>
      </ul>
      <div v-else class="w-full h-24 p-12 text-center">Nema notifikacija</div>
      
      <div
      v-if="filtered_list.length > 0 && active_notification_type == 0"
        @click="openAllNotification"
        href="#"
        class="block py-2 cursor-pointer  text-sm font-medium text-center text-gray-900 rounded-b-lg bg-gray-50 hover:bg-gray-100 dark:bg-gray-800 dark:hover:bg-gray-700 dark:text-white"
      >
        <div class="inline-flex items-center">
          <svg
            class="w-4 h-4 me-2 text-gray-500 dark:text-gray-400"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 20 14"
          >
            <path
              d="M10 0C4.612 0 0 5.336 0 7c0 1.742 3.546 7 10 7 6.454 0 10-5.258 10-7 0-1.664-4.612-7-10-7Zm0 10a3 3 0 1 1 0-6 3 3 0 0 1 0 6Z"
            />
          </svg>
          Označi kao pročitano
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useNotificationStore } from "@/store/notifications";
import { Dropdown, initDropdowns } from "flowbite";
import moment from "moment";
import { onMounted, ref, computed } from "vue";
import gsap from "gsap";

export default {
  name: "NotificationBell",

  setup() {
    const notification_store = useNotificationStore();
    const active_notification_type = ref(0);
    const notification_transition_obj = ref({opacity:0, x:200, display:"none", duration:1})
    // 0 -> opened
    // 1 -> unopened

    const notifications = ref(notification_store.notifications);
    const has_unopened_notifications = computed(()=>{
      return notifications.value.filter((notification) => !notification.opened).length > 0;
    })

    const getUnopenedNotifications = () => {
      let num_of_unopened = notifications.value.filter((notification) => !notification.opened)
        .length;
        if (num_of_unopened > 0) {
          return "(" + num_of_unopened + ")";
        }else{
          return "";
        }

    };
    const filtered_list = computed(()  => {
      if (active_notification_type.value == 1) {
        return notifications.value.filter((notification) => notification.opened);
      } else {
        return notifications.value.filter((notification) => !notification.opened);
      }
    });

    const openNotification = (notif)=>{
      if(!notif.opened){
        gsap.to("#notif-"+notif.id, notification_transition_obj.value).then(()=>{
          
          useNotificationStore().userReadSingleNotification(notif.id)
        })
        // useNotificationStore().userReadSingleNotification(notif.id)
        // console.log("Notification", notif);
        // document.getElementById("dropdownNotificationButton").click();
      }
    }
    const openAllNotification = ()=>{
    
          useNotificationStore().userReadAllNotification()
      
    }

    const unopenNotification = (notif)=>{
        gsap.to("#notif-"+notif.id, notification_transition_obj.value).then(()=>{
          // setTimeout(() => {
            useNotificationStore().userUnreadSingleNotification(notif.id)
          // }, 1000);
          // useNotificationStore().userUnreadSingleNotification(notif.id)

        })
    }
    const getNotificationGeneralTitle = (title)=>{
      if(title.startsWith("/comunication")){
        return "Odogovor u komunikaciji";
      }else if(title.startsWith("/files")){
        return "Dijeljena datoteka";
      }else{
        return "Operativne obavijesti";
      }
    }
    const fromWhenIsNotification = (date) => {
      console.log("Date", date);
      return moment(date).locale("hr").fromNow();
    };
    console.log("Notifications", notifications);
    onMounted(() => {
      initDropdowns();
    });
    return {
      notifications,
      active_notification_type,
      filtered_list,
      has_unopened_notifications,
      fromWhenIsNotification,
      getUnopenedNotifications,
      openNotification,
      unopenNotification,
      openAllNotification,
      getNotificationGeneralTitle
    };
  },
};
</script>
