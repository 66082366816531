import Toastify from "toastify-js";
const errorToast = (message) => {
    Toastify({
        text: message,
        duration: 3000,
        destination: null,
        close: true,
        gravity: "bottom", // `top` or `bottom`
        position: "right", // `left`, `center` or `right`
        stopOnFocus: true, // Prevents dismissing of toast on hover
        style: {
          background: "linear-gradient(to right, #FF4E50, #F9D423)",
        },
      }).showToast();
}


const successToastMessage = (message) => {
  Toastify({
      text: message,
      duration: 3000,
      destination: null,
      close: true,
      gravity: "top", // `top` or `bottom`
      position: "right", // `left`, `center` or `right`
      stopOnFocus: true, // Prevents dismissing of toast on hover
      style: {
        background: "linear-gradient(to right, #1F4451, #507482)", // Green gradient
      },
    }).showToast();
}


export { errorToast,successToastMessage };