<script setup>
import { onMounted, ref } from "vue";

import { supabase } from "./services/supabase";
import store from "./store";
import NavBar from "./components/Navigation/NavBar.vue";
import { useUserStore } from "./store/auth.session";
import { getCurrentUserData } from "./services/user.service";
import { setupSocketConnection } from "./lib/socket.js";

const session = ref();
const current_user = ref(null);
const userStore = useUserStore();
import {
  initAccordions,
  initCarousels,
  initCollapses,
  initDials,
  initDismisses,
  initDrawers,
  initDropdowns,
  initModals,
  initPopovers,
  initTabs,
  initTooltips,
} from "flowbite";
import { useNotificationStore } from "./store/notifications";

onMounted(async () => {
  initAccordions();
  initCarousels();
  initCollapses();
  initDials();
  initDismisses();
  initDrawers();
  initDropdowns();
  initModals();
  initPopovers();
  initTabs();
  initTooltips();

  console.log(useUserStore());
  supabase.auth.getSession().then(async ({ data }) => {
  //   session.value = data.session;
    console.log(data.session);
    console.log(supabase.auth.refreshSession())
    if (data.session != null) {
      await getCurrentUserData()
        .then((resp) => {
          try{
            console.log("Response here", resp.data);
          userStore.user_profile = resp.data;
          useNotificationStore().addNotificationArray(resp.data.client_portal_notifications);
          current_user.value = resp.data;
          if (session.value != undefined) {
            setupSocketConnection();
          }
          }catch(err){
            supabase.auth.refreshSession()
            userStore.logoutUser();
          }

        })
        .catch(() => {
          supabase.auth.refreshSession()
          userStore.logoutUser();
        });
      console.log("can call mutation", userStore.user_profile);
    }
  });

  supabase.auth.onAuthStateChange((_, _session) => {
    session.value = _session;
  });
});
</script>

<template>
  <div class="app" id="main-wrapp">
    <nav-bar v-if="current_user"></nav-bar>
    <router-view class="mx-auto ml-24 z-10" />
  </div>
</template>
