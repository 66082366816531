import { createRouter, createWebHistory } from "vue-router";
import IndexView from "../views/IndexView.vue";
import LoginView from "../views/LoginView.vue";
import ProfileView from "../views/ProfileView.vue";
import CaseOverview from "../views/case/OverviewView";
import FinancialView from "../views/case/FinancialView";
import CaseFileView from "../views/case/FileView";
import CaseIndexView from "../views/case/CaseIndexView";
import CaseComunication from "../views/case/ComunicationView.vue";
import GeneralComunication from "../views/ComunicationView.vue";

import { supabase } from "../services/supabase";
import CalendarViewVue from "@/views/CalendarView.vue";
import FileView from "@/views/FileView.vue";


const routes = [
  {
    path: "/",
    name: "home",
    component: IndexView,
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
  },
  {
    path: "/profile",
    name: "profile",
    component: ProfileView,
  },
  {
    path: "/case",
    name: "case-index",
    component: CaseIndexView,
  },
  {
    path: "/calendar",
    name: "calendar-index",
    component: CalendarViewVue,
  },
  {
    path: "/files",
    name: "files-index",
    component: FileView,
  },
  {
    path: "/comunication",
    name: "general-comunication",
    component: GeneralComunication,
  },
  {
    path: "/case/:uid",
    name: "case-overview",
    component: CaseOverview,
  },
  {
    path: "/case/:uid/comunication",
    name: "case-comunication",
    component: CaseComunication,
  },
  {
    path: "/case/:uid/file",
    name: "case-file",
    component: CaseFileView,
  },
  {
    path: "/case/:uid/financial",
    name: "case-financial",
    component: FinancialView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const publicPages = ["/login"];
  const authRequired = !publicPages.includes(to.path);

  supabase.auth.getSession().then(({ data }) => {
    console.log("tu je", authRequired, to.path);

    const loggedIn = data.session != null;
    if (authRequired) {
      console.log("tu je", authRequired, to.path);

      if (loggedIn) {
        if (to.fullPath.includes("/login")) {
          console.log("tu je");
          next("/");
        } else {
          next();
        }
      } else {
        if (to.path == "/login") {
          next();
        } else {
          next("/login");
        }
      }
    } else {
        if(loggedIn)
        {
          next("/")
        }else{
          next();
        }
        
        
    }
  });

  // trying to access a restricted page + not logged in
  // redirect to login page
  // if (authRequired && !loggedIn) {
  //   next('/login');
  // } else {
  // if(to.path == "/login")
  // {
  //   next("/")
  // }else{
  // next();
  // }
  // }
});

export default router;
