import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import { createPinia } from 'pinia'


import 'v-onboarding/dist/style.css'
import "toastify-js/src/toastify.css"


import './assets/style/default.css'
import "./assets/style/style.css"


const clickOutside = {
    beforeMount: (el, binding) => {
      el.clickOutsideEvent = event => {
        // here I check that click was outside the el and his children
        if (!(el == event.target || el.contains(event.target))) {
            console.log("click outside", el)
          // and if it did, call method provided in attribute value
        //   binding.value();
        }
      };
      document.addEventListener("click", el.clickOutsideEvent);
    },
    unmounted: el => {
      document.removeEventListener("click", el.clickOutsideEvent);
    },
  };


createApp(App).use(store).use(createPinia()).directive("click-outside", clickOutside).use(router).mount('#app')
