<template>
  <div class="z-90">
    <button
      data-modal-target="case-message-modal"
      data-modal-toggle="case-message-modal"
      id="ob-case-3"
      class="block text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm px-4 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
      type="button"
    >
     <span class="pr-2 text-lg">+</span> Pošaljite upit
    </button>
    <!-- <button data-modal-target="case-message-modal" data-modal-toggle="case-message-modal" class="block text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" type="button">
Toggle modal
</button> -->
    <div
      id="case-message-modal"
      tabindex="-1"
      style="z-index: 100000;"
      class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-90 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full backdrop-blur-sm "
    >
      <div class="relative w-1/2 w-4xl max-h-full bg-gray-700 ">
        <!-- Modal content -->
        <div class="relative bg-custom-container py-2 px-2 shadow bg-gray-700">
          <!-- Modal header -->
          <div
            class="flex items-center justify-between p-4 md:p-5  rounded-t dark:border-gray-600"
          >
            <h3 class="text-xl font-semibold text-gray-100 ">
              Upit uz predmet {{ case_name }}
            </h3>
            <button
              type="button"
              class="text-gray-400 bg-transparent hover:bg-gray-200  rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center hover:bg-gray-600 "
              data-modal-toggle="case-message-modal"
              id="cls-btn"
            >
              <svg
                class="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span class="sr-only">Close modal</span>
            </button>
          </div>
          <!-- Modal body -->
          <div class="p-4 md:p-5">
            <div class="grid gap-4 mb-4 grid-cols-2">
              <div class="col-span-2">
                <label
                  for="name"
                  class="block mb-4 text-md font-medium text-gray-100  "
                  >Naslov upita</label
                >
                <input
                  type="text"
                  name="name"
                  id="name"
                  class="bg-gray-600 border border-gray-300 text-gray-100 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400  dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  placeholder="Type product name"
                  required=""
                  v-model="final_comunication_object.title"
                />
              </div>

              <div class="col-span-2 mb-6">
                <label
                  for="description"
                  class="block mb-4 text-md font-medium text-gray-100 "
                  >Tekst upita</label
                >
                <textarea
                  id="description"
                
                  rows="4"
                  class="block p-2.5 w-full text-sm text-gray-100 bg-gray-600 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Ovdje upišite tekst upita"
                  v-model="final_comunication_object.message_text"
                ></textarea>
              </div>
            </div>
            <button
              @click="sendMessage()"
              :disabled="btn_active"
              class="text-white inline-flex items-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              <svg
                fill="currentColor"
                class="w-4 h-4 mr-2"
                viewBox="0 0 32 32"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  <title>send-envelope</title>
                  <path
                    d="M0 25.984v-12q0-2.464 1.76-4.224t4.256-1.76h0.352l0.096 0.288q0.544 1.344 1.344 2.176t2.208 1.184v0.352h-4q-0.672 0-1.28 0.48l11.264 7.52 11.264-7.52q-0.576-0.48-1.248-0.48h-4v-0.352q1.376-0.384 2.176-1.184t1.344-2.176l0.096-0.288h0.384q2.464 0 4.224 1.76t1.76 4.224v12q0 2.496-1.76 4.256t-4.224 1.76h-20q-2.496 0-4.256-1.76t-1.76-4.256zM4 23.968l6.016-1.984-5.408 5.408q0.544 0.608 1.408 0.608h20q0.8 0 1.408-0.608l-5.408-5.408 5.984 2.016v-8.032l-12 8-12-8v8zM10.048 5.632q0.096-0.608 0.544-1.056l4-4q0.576-0.576 1.408-0.576t1.408 0.576l4 4q0.448 0.448 0.544 1.056t-0.096 1.152q-0.224 0.544-0.736 0.896t-1.12 0.32h-1.984v6.016q0 0.832-0.608 1.408t-1.408 0.576-1.408-0.576-0.576-1.408v-6.016h-2.016q-0.608 0-1.12-0.32t-0.736-0.896-0.096-1.152z"
                  ></path>
                </g>
              </svg>
              {{ msg_btn }}
            </button>
          </div>
        </div>
      </div>
      
    </div>
  </div>
</template>

<script>
import router from "@/router";
import { initModals } from "flowbite";
import { onMounted, ref } from "vue";
import { createNewMessage } from "@/services/comunication.user.js";
import { errorToast, successToastMessage } from "@/lib/toastifyNotifications";
import $ from "jquery";

export default {
  props: {
    case_name: {
      type: Text,
      required: true,
    },
  },
  emits : ["message-sent"],
  setup(props,context) {
    const case_id = ref(router.currentRoute.value.params.uid);
    const msg_btn = ref("Pošaljite upit");
    const btn_active = ref(false);
    const final_comunication_object = ref({
      title: "Upit uz predmet " + props.case_name,
      message_text: "",
    });
    const sendMessage = async() => {
      msg_btn.value = "Slanje...";
      btn_active.value = true;
      if(final_comunication_object.value.message_text.trim() === "" || final_comunication_object.value.title.trim() === ""){
        errorToast("Molimo ispunite sva polja upita.");
        btn_active.value = false;
        msg_btn.value="Pošaljite upit";
        return;
      }else{
        await createNewMessage(case_id.value, final_comunication_object.value).then((res) => {
        console.log(res);
        btn_active.value = false;
        successToastMessage("Poruka uspješno poslana. Dobiti ćete odgovor u najkraćem mogućem roku.");
        
        context.emit("message-sent","Poruka uspješno poslana.");
        $("#cls-btn").click();

      }).catch((err) => {
        console.log(err);
        btn_active.value = false;
        errorToast("Greška prilikom slanja poruke. Molimo pokušajte ponovo.");
      });
      }
      
    };
    
    onMounted(() => {
      initModals();
    });
    return {
      msg_btn,
      btn_active,
      final_comunication_object,
      case_id,
      sendMessage
    };
  },
};
</script>

<style>
/* Add your styles here */
</style>
