import { reactive } from 'vue' // if needed reactive state for connected
import { io } from 'socket.io-client' // for connection on socket
import { useUserStore } from '@/store/auth.session'
import { notification_receive_functions } from './notifications_functions' // All function regarding notifications
// import { useGeneralStore } from '@/stores/general_store'

let userStore
export const state = reactive({
    connected: false
})

const URL = process.env.VUE_APP_GENERAL_API
let socket

/**
 * Method that sets up socket connection between client/user and server.
 * The initial header needs to heave user id-s.
 * Need to potentialy upgrade down the line
 */
const setupSocketConnection = async () => {
  console.log("new socket connection on switch")
    userStore = useUserStore()
    // const general_store = useGeneralStore();
    socket = io(URL, {
        query: {
            
                client_id: userStore.user_profile.uid,
        }
    })

    notification_receive_functions.forEach((single_instance) => {
        socket.on(single_instance.endpoint, single_instance.function) //Go trough each endpoint and hook a function inside.
    }) 
    socket.on('CLIENT_PORTAL_CONNECTED', (ret_val) => {
        console.log("Connected to socket", ret_val)
        state.connected = true
        socket.emit('CLIENT_PORTAL_CONNECTED', {client_id: userStore.user_profile.uid})
    })
}





export {socket, setupSocketConnection }