<template>
    <div class="general-wrapp mx-auto" >
    <div v-if="caseData != null">
                
      <CaseNavigation :activeTab="3" :caseData="caseData"></CaseNavigation>
                          
                <section class="pt-8 ">
                  <div class="container px-4 mx-auto">
                    <div class="mx-auto">
                      <div class="flex flex-wrap -mx-4 mb-6">
                        <div class="w-full md:w-1/2 px-4 mb-6 md:mb-0">
                          <div class="h-full p-6 bg-custom-container rounded-xl">
                            <div class="flex items-center">
                              <div class="flex flex-shrink-0 self-start w-12 h-12 mr-4 items-center justify-center bg-blue-500 bg-opacity-20 text-blue-500 rounded-xl">
                                <svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M8.67188 14.3298C8.67188 15.6198 9.66188 16.6598 10.8919 16.6598H13.4019C14.4719 16.6598 15.3419 15.7498 15.3419 14.6298C15.3419 13.4098 14.8119 12.9798 14.0219 12.6998L9.99187 11.2998C9.20187 11.0198 8.67188 10.5898 8.67188 9.36984C8.67188 8.24984 9.54187 7.33984 10.6119 7.33984H13.1219C14.3519 7.33984 15.3419 8.37984 15.3419 9.66984" stroke="#194BFB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                  <path d="M12 6V18" stroke="#194BFB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                  <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#194BFB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                </svg>
                              </div>
                              <div>
                                <h5 class="text-xs text-gray-300 leading-6 font-semibold">Ukupno fakturirano</h5>
                                <div class="flex items-end leading-none text-gray-100 font-semibold">
                                  <span class="text-2xl">{{caseData.total}}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="w-full md:w-1/2 px-4">
                          <div class="h-full p-6 bg-custom-container rounded-xl">
                            <div class="flex items-center">
                              <div class="flex flex-shrink-0 self-start w-12 h-12 mr-4 items-center justify-center bg-blue-500 bg-opacity-20 text-blue-500 rounded-xl">
                                <svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M8.67188 14.3298C8.67188 15.6198 9.66188 16.6598 10.8919 16.6598H13.4019C14.4719 16.6598 15.3419 15.7498 15.3419 14.6298C15.3419 13.4098 14.8119 12.9798 14.0219 12.6998L9.99187 11.2998C9.20187 11.0198 8.67188 10.5898 8.67188 9.36984C8.67188 8.24984 9.54187 7.33984 10.6119 7.33984H13.1219C14.3519 7.33984 15.3419 8.37984 15.3419 9.66984" stroke="#194BFB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                  <path d="M12 6V18" stroke="#194BFB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                  <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#194BFB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                </svg>
                              </div>
                              <div>
                                <h5 class="text-xs text-gray-300 leading-6 font-semibold">Neisplaćeno</h5>
                                <div class="flex items-center text-2xl leading-none text-gray-100 font-semibold">
                                  <span>{{caseData.unpaid}}</span>
                                  
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      
                      <div class="p-6 mb-10 bg-custom-container rounded-xl">
                        <h4 class="text-lg text-gray-100 font-semibold mb-6">Pregled Faktura</h4>
                        <div class="w-full mt-6 pb-4 overflow-x-auto">
                          <table class="w-full min-w-max">
                            <thead>
                              <tr class="text-left">
                                <th class="p-0">
                                  <div class="py-3 px-6 rounded-l-xl bg-gray-600">
                                    <span class="text-xs text-gray-300 font-semibold">Faktura ID</span>
                                  </div>
                                </th>
                                <th class="p-0">
                                  <div class="py-3 px-6 bg-gray-600">
                                    <span class="text-xs text-gray-300 font-semibold">AMOUNT</span>
                                  </div>
                                </th>
                                <th class="p-0">
                                  <div class="py-3 px-6 bg-gray-600">
                                    <button class="inline-flex items-center text-xs text-gray-300 font-semibold">
                                      <span class="mr-2">DATE</span>
                                      <svg width="10" height="14" viewbox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.8598 8.52669L4.9998 11.3934L2.1398 8.52669C2.01426 8.40115 1.844 8.33063 1.66646 8.33063C1.48893 8.33063 1.31867 8.40115 1.19313 8.52669C1.0676 8.65222 0.99707 8.82249 0.99707 9.00002C0.99707 9.17756 1.0676 9.34782 1.19313 9.47335L4.52646 12.8067C4.58844 12.8692 4.66217 12.9188 4.74341 12.9526C4.82465 12.9865 4.91179 13.0039 4.9998 13.0039C5.08781 13.0039 5.17494 12.9865 5.25618 12.9526C5.33742 12.9188 5.41116 12.8692 5.47313 12.8067L8.80646 9.47335C8.86862 9.41119 8.91793 9.3374 8.95157 9.25619C8.98521 9.17497 9.00252 9.08793 9.00252 9.00002C9.00252 8.91211 8.98521 8.82507 8.95157 8.74385C8.91793 8.66264 8.86862 8.58885 8.80646 8.52669C8.7443 8.46453 8.67051 8.41522 8.5893 8.38158C8.50808 8.34794 8.42104 8.33063 8.33313 8.33063C8.24522 8.33063 8.15818 8.34794 8.07696 8.38158C7.99575 8.41522 7.92196 8.46453 7.8598 8.52669ZM2.1398 5.47335L4.9998 2.60669L7.8598 5.47335C7.92177 5.53584 7.99551 5.58544 8.07675 5.61928C8.15799 5.65313 8.24512 5.67055 8.33313 5.67055C8.42114 5.67055 8.50828 5.65313 8.58952 5.61928C8.67075 5.58544 8.74449 5.53584 8.80646 5.47335C8.86895 5.41138 8.91855 5.33764 8.95239 5.2564C8.98624 5.17517 9.00366 5.08803 9.00366 5.00002C9.00366 4.91201 8.98624 4.82488 8.95239 4.74364C8.91855 4.6624 8.86895 4.58866 8.80646 4.52669L5.47313 1.19335C5.41116 1.13087 5.33742 1.08127 5.25618 1.04743C5.17494 1.01358 5.08781 0.996155 4.9998 0.996155C4.91179 0.996155 4.82465 1.01358 4.74341 1.04743C4.66217 1.08127 4.58844 1.13087 4.52646 1.19335L1.19313 4.52669C1.0676 4.65222 0.99707 4.82249 0.99707 5.00002C0.99707 5.17756 1.0676 5.34782 1.19313 5.47335C1.31867 5.59889 1.48893 5.66941 1.66646 5.66941C1.844 5.66941 2.01426 5.59889 2.1398 5.47335Z" fill="currentColor"></path>
                                      </svg>
                                    </button>
                                  </div>
                                </th>
                                <th class="p-0">
                                  <div class="py-3 px-5 rounded-r-xl bg-gray-600">
                                    <span class="text-xs text-gray-300 font-semibold">STATUS</span>
                                  </div>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td class="p-0">
                                  <div class="h-16 p-6">
                                    <h5 class="text-sm font-medium text-gray-100">Faktura #3405</h5>
                                  </div>
                                </td>
                                <td class="p-0">
                                  <div class="h-16 p-6">
                                    <span class="text-sm font-medium text-gray-100">€19947.76</span>
                                  </div>
                                </td>
                                <td class="p-0">
                                  <div class="h-16 p-6">
                                    <span class="text-sm text-gray-100 font-medium">July 06, 2022</span>
                                  </div>
                                </td>
                                <td class="p-0">
                                  <div class="h-16 p-6">
                                    <div class="flex h-full items-center">
                                      <span class="inline-block w-2 h-2 mr-1 bg-yellow-500 rounded-full"></span>
                                      <span class="text-sm font-medium text-gray-100">Poslano</span>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td class="p-0">
                                  <div class="h-16 p-6 rounded-l-xl bg-gray-600">
                                    <h5 class="text-sm font-medium text-gray-100">Faktura #3405</h5>
                                  </div>
                                </td>
                                <td class="p-0">
                                  <div class="h-16 p-6 bg-gray-600">
                                    <span class="text-sm font-medium text-gray-100">€19947.76</span>
                                  </div>
                                </td>
                                <td class="p-0">
                                  <div class="h-16 p-6 bg-gray-600">
                                    <h5 class="text-sm font-medium text-gray-100">July 06, 2022</h5>
                                  </div>
                                </td>
                                <td class="p-0">
                                  <div class="flex h-16 p-6 items-center rounded-r-xl bg-gray-600">
                                    <span class="inline-block w-2 h-2 mr-1 bg-green-500 rounded-full"></span>
                                    <span class="text-sm font-medium text-gray-100">Fakturirano</span>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td class="p-0">
                                  <div class="h-16 p-6">
                                    <h5 class="text-sm font-medium text-gray-100">Faktura #3405</h5>
                                  </div>
                                </td>
                                <td class="p-0">
                                  <div class="h-16 p-6">
                                    <span class="text-sm font-medium text-gray-100">€19947.76</span>
                                  </div>
                                </td>
                                <td class="p-0">
                                  <div class="h-16 p-6">
                                    <span class="text-sm text-gray-100 font-medium">July 06, 2022</span>
                                  </div>
                                </td>
                                <td class="p-0">
                                  <div class="h-16 p-6">
                                    <div class="flex h-full items-center">
                                      <span class="inline-block w-2 h-2 mr-1 bg-yellow-500 rounded-full"></span>
                                      <span class="text-sm font-medium text-gray-100">Poslano</span>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            
                              <tr>
                                <td class="p-0">
                                  <div class="h-16 p-6">
                                    <h5 class="text-sm font-medium text-gray-100">Faktura #3405</h5>
                                  </div>
                                </td>
                                <td class="p-0">
                                  <div class="h-16 p-6">
                                    <span class="text-sm font-medium text-gray-100">€19947.76</span>
                                  </div>
                                </td>
                                <td class="p-0">
                                  <div class="h-16 p-6">
                                    <span class="text-sm text-gray-100 font-medium">July 06, 2022</span>
                                  </div>
                                </td>
                                <td class="p-0">
                                  <div class="h-16 p-6">
                                    <div class="flex h-full items-center">
                                      <span class="inline-block w-2 h-2 mr-1 bg-yellow-500 rounded-full"></span>
                                      <span class="text-sm font-medium text-gray-100">Poslano</span>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                              
                            </tbody>
                          </table>
                        </div>
                      </div>
                      
                    </div>
                  </div>
                </section>
                          
                <section class="pb-3"><div class="container px-4 mx-auto">
                  <div class="px-5 py-6 bg-custom-container rounded-xl">
                    <div class="flex h-8 mx-auto w-min">
                      <a class="inline-flex items-center justify-center w-8 bg-gray-600 border-r border-gray-500 text-gray-400 hover:text-gray-300 rounded-l-lg" href="#">
                        <svg width="4" height="6" viewbox="0 0 4 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.6454 2.99997L3.4154 1.22997C3.50852 1.13629 3.5608 1.00957 3.5608 0.877474C3.5608 0.745381 3.50852 0.618656 3.4154 0.524974C3.36892 0.47811 3.31362 0.440913 3.25269 0.415529C3.19176 0.390144 3.12641 0.377075 3.0604 0.377075C2.99439 0.377075 2.92904 0.390144 2.86811 0.415529C2.80718 0.440913 2.75188 0.47811 2.7054 0.524974L0.585399 2.64497C0.538535 2.69146 0.501338 2.74676 0.475954 2.80769C0.450569 2.86862 0.4375 2.93397 0.4375 2.99997C0.4375 3.06598 0.450569 3.13133 0.475954 3.19226C0.501338 3.25319 0.538535 3.30849 0.585399 3.35497L2.7054 5.49997C2.75212 5.54631 2.80753 5.58298 2.86845 5.60786C2.92936 5.63274 2.9946 5.64536 3.0604 5.64497C3.1262 5.64536 3.19143 5.63274 3.25235 5.60786C3.31327 5.58298 3.36868 5.54631 3.4154 5.49997C3.50852 5.40629 3.5608 5.27957 3.5608 5.14747C3.5608 5.01538 3.50852 4.88866 3.4154 4.79497L1.6454 2.99997Z" fill="currentColor"></path></svg></a>
                      <a class="inline-flex items-center justify-center w-8 bg-gray-600 border-r border-gray-500 text-sm text-gray-100 font-semibold" href="#">1</a><a class="inline-flex items-center justify-center w-8 bg-gray-600 border-r border-gray-500 text-sm text-gray-400 hover:text-gray-300 font-semibold" href="#">2</a>
                      <a class="inline-flex items-center justify-center w-8 bg-gray-600 text-gray-400 hover:text-gray-300 rounded-r-lg" href="#">
                        <svg width="4" height="6" viewbox="0 0 4 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.3546 3.00003L0.584601 4.77003C0.491475 4.86371 0.439205 4.99043 0.439205 5.12253C0.439205 5.25462 0.491475 5.38134 0.584601 5.47503C0.631083 5.52189 0.686383 5.55909 0.747313 5.58447C0.808242 5.60986 0.873595 5.62293 0.939601 5.62293C1.00561 5.62293 1.07096 5.60986 1.13189 5.58447C1.19282 5.55909 1.24812 5.52189 1.2946 5.47503L3.4146 3.35503C3.46146 3.30854 3.49866 3.25324 3.52405 3.19231C3.54943 3.13138 3.5625 3.06603 3.5625 3.00003C3.5625 2.93402 3.54943 2.86867 3.52405 2.80774C3.49866 2.74681 3.46146 2.69151 3.4146 2.64503L1.2946 0.500026C1.24788 0.453685 1.19247 0.417023 1.13155 0.392141C1.07063 0.367258 1.0054 0.354645 0.9396 0.355026C0.873797 0.354645 0.808566 0.367258 0.747647 0.392141C0.686728 0.417023 0.631321 0.453686 0.5846 0.500026C0.491475 0.593707 0.439204 0.720433 0.439204 0.852526C0.439204 0.984619 0.491475 1.11134 0.584601 1.20503L2.3546 3.00003Z" fill="currentColor"></path></svg></a>
                    </div>
                  </div>
                  </div>
                </section>
              </div>
              </div>
</template>


<script>
import { ref, onMounted } from "vue";
import gsap from "gsap";
import router from "@/router";
import store from "@/store";
import CaseNavigation from "@/components/Navigation/CaseNavigation.vue";
import { getSingleCaseOverview } from "@/services/case.service";

export default {
  setup() {
    const caseData = ref(null);
    const single_case_uid = router.currentRoute.value.params.uid;

    onMounted(async () => {
      await getSingleCaseOverview(single_case_uid).then((resp) => {
        caseData.value = resp.data;
        gsap.from(".general-wrapp", {
          duration: 1,
          x: -100,
          opacity: 0,
          ease: "power1.out",
          force3D: true,
        });

        console.log(caseData.value)
      });
    });
    return {
      caseData
    };
  },
  components: { CaseNavigation },
};
</script>
