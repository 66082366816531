import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.VUE_APP_API_ENDPOINT_STARTER;

const getCurrentUserData = async ()=> {
    return axios.get(API_URL + 'get-user', { headers: await authHeader() });
  }

export{
    getCurrentUserData
}
