// import axios from 'axios';

// const API_URL = process.env.VUE_APP_API_ENDPOINT_STARTER;

// class AuthService {
//   login(user) {
//     return axios
//       .post(API_URL + 'sign-in', {
//         username: user.username,
//         password: user.password
//       })
//       .then(response => {
//         console.log(response)
//         if (response.data.token) {
//           localStorage.setItem('user', JSON.stringify(response.data));
//         }

//         return response.data;
//       });
//   }

//   logout() {
//     localStorage.removeItem('user');
//     window.location.reload();
//   }

//   register(user) {
//     return axios.post(API_URL + 'signup', {
//       username: user.username,
//       email: user.email,
//       password: user.password
//     });
//   }
// }

// export default new AuthService();


import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.VUE_APP_API_ENDPOINT_STARTER;

const requestUserOTP = async (username)=> {
    return axios.post(API_URL + 'sign-in', {
      username: username,
    });
  }

export{
  requestUserOTP
}
