import { supabase } from '../services/supabase'

export default async function authHeader() {
    let user = JSON.parse(localStorage.getItem('user'));

    const {data} = await supabase.auth.getSession();
    if (data.session) {
      console.log(data.session.access_token);

      return { Authorization: 'Bearer ' + data.session.access_token }; // for Spring Boot back-end
      // return { 'x-access-token': user.accessToken };       // for Node.js Express back-end
    } else {
      return {};
    }
  }