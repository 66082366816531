import { useNotificationStore } from "@/store/notifications";

const notification_receive_functions = [
    {
        endpoint: 'CLIENT_PORTAL_NEW_NOTIFICATION',
        function: (data) => {
            const notifications_store = useNotificationStore();
            notifications_store.add_new_notification(JSON.parse(data));
            
           
        }
    },
];

export { notification_receive_functions }