<template>
  <VOnboardingWrapper ref="wrapper" :steps="steps">
    <template #default="{ previous, next, step, exit, isFirst, isLast, index }">
      <VOnboardingStep>
        <div
          class="bg-gray-800 bg-opacity-80 overflow-y-auto shadow sm:rounded-lg"
        >
          <div
            class="max-w-xl w-full mx-auto bg-gray-500 rounded-xl overflow-hidden"
          >
            <div class="max-w-md mx-auto pt-12 pb-12 px-5">
              <h4
                class="text-xl text-gray-100 font-semibold mb-5"
                v-if="step.content.title"
              >
                {{ step.content.title }}
              </h4>
              <p class="text-gray-300 text-lg" v-if="step.content.description">
                {{ step.content.description }}
              </p>
            </div>
            <div class="pt-5 pb-6 px-6 py-2 text-right bg-gray-600 -mb-2">
              <a
                v-if="!isFirst"
                class="inline-block w-full sm:w-auto py-3 px-5 mb-2 mr-4 text-center font-semibold leading-6 text-gray-200 bg-gray-500 hover:bg-gray-400 rounded-lg transition duration-200"
                href="#"
                @click="previous"
                >Prethodno</a
              ><a
                class="inline-block w-full sm:w-auto py-3 px-5 mb-2 mr-4 text-center font-semibold leading-6 text-blue-50 bg-blue-500 hover:bg-blue-600 rounded-lg transition duration-200"
                href="#"
                @click="next"
                >{{ isLast ? "Završi" : "Iduće" }}</a
              >
              <a
                v-if="!isLast"
                class="inline-block w-full sm:w-auto py-3 px-5 mb-2 text-center font-semibold leading-6 text-blue-50 border-sky-500 hover:bg-indigo-800 rounded-lg transition duration-500"
                href="#"
                @click="finish"
                >Preskoči</a
              >
            </div>
          </div>
        </div>
      </VOnboardingStep>
    </template>
  </VOnboardingWrapper>
  <button data-tooltip-target="help-tooltip"
        data-tooltip-placement="right" type="button" class="fixed bottom-2 right-0 text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl  font-medium rounded-full text-lg w-12 h-12 text-center me-2 mb-2" @click="openOnboading()">?</button>
      <div
        id="help-tooltip"
        role="tooltip"
        class="absolute  invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700"
      >Upustva za korištenje</div>
</template>

<script>
import { ref, onMounted } from "vue";
import {
  VOnboardingWrapper,
  VOnboardingStep,
  useVOnboarding,
} from "v-onboarding";
import "v-onboarding/dist/style.css";
import { useNotificationStore } from "@/store/notifications";
import { useUserStore } from "@/store/auth.session";
import router from "@/router";
import {
  initTooltips,
} from "flowbite";

// @ is an alias to /src

export default {
  components: {
    VOnboardingWrapper,
    VOnboardingStep,
  },
  props: {
    steps: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const user_store = useUserStore();
    const current_user = ref(user_store.user_profile);
    const wrapper = ref(null);
    const { start, goToStep, finish } = useVOnboarding(wrapper, {});
    const steps = ref(props.steps);
    console.log("Steps", steps.value);

    const openOnboading = () => {
      start();
    };
    onMounted(() => {
      initTooltips();
      if (
        !current_user.value.onboarding_routes.includes(
          router.currentRoute.value.name
        )
      ){
        start();
        useNotificationStore().add_client_onboarding_route(router.currentRoute.value.name);
      }else{
        console.log("Already completed onboarding for route " + router.currentRoute.value.name);
      }
        
    });

    return {
      current_user,
      wrapper,
      steps,
      finish,
      openOnboading
    };
  },
};
</script>
